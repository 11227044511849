import DrawerBottomButtons from '@/components/Drawer/ContentParams/DrawerBottomButtons'
import {NBASettings} from '@/components/NBAConfigEditor/NBASettings'
import {useNBAConfigEditor} from '@/components/NBAConfigEditor/useNBAConfigEditor.hook'
import {useNBAMetaConfig} from '@/hooks/useNBAMetaConfig'
import {Button} from '@/shared-components/shadcn/button'
import {useCallback} from 'react'
import {useIntl} from 'react-intl'
import {useAppDispatch} from '../../store'
import {closeNBADrawer, setActiveNBA} from '@ReduxActions'
import {setCancelPage} from '@/store/slices/commonSlice'

export const NBAConfigEditor = () => {
  const {formatMessage} = useIntl()
  // const {onCloseDrawer} = useNBADrawer()
  // const isEditMode = useAppSelector(state => state.common.editMode)
  const dispatch = useAppDispatch()
  const {status, isPublishedOrCompleted, isNew: isNewNBA} = useNBAMetaConfig()
  const {
    onNameChanged,
    onDescriptionChanged,
    onPeriodDidChange,
    onAddLanguage,
    onChangeAward,
    onFixRewardPointsChanged,
    onThresholdChanged,
    onThresholdFileUpdate,
    onFixThresholdChanged,
    onChangeRewardType,
    onRewardFileUpdate,
    // onRecurrenceDidChange,
    onCountryCodeChanged,
    onCountryChanged,
    isFormError,
    temporaryConfig,
    hasUnsavedChanges,
    isAllRequiredFilled,
    formErrors,
    saveTemporaryChanges,
    // onNBATypeDidChange,
    selectedDateInJsDate,
    isUpdatingDraft,
    onDisplayPeriodDidChange,
    onAddTask,
    onDateDidChange,
    openNBAPreview,
  } = useNBAConfigEditor()

  // const confirmChangesAndCloseDrawer = useCallback(() => {
  //   if (!isEditMode) openNBAPreview()
  // }, [isEditMode, openNBAPreview])

  const onSaveDraft = useCallback(() => {
    saveTemporaryChanges(() => {
      if (isNewNBA) {
        // const id = canvasApp.getNodes().first.getID()
        // dispatch(setCurrentNode({id, type: NodeTypeEnum.entryCondition}))
      }
    })
  }, [saveTemporaryChanges, isNewNBA])

  if (isPublishedOrCompleted) {
    return null
  }

  return (
    // mb-16 is the DrawerBottomButtons height
    <div className="mb-16 flex flex-col gap-6" data-testid="nba-config-editor">
      <div className="flex flex-row">
        <div className="flex flex-col pl-2">
          <Button
            className="mb-2 flex border border-auxiliary-100	bg-[transperent] px-14	text-xs text-primary"
            variant="outline"
          >
            {formatMessage({id: 'NBA_BUILDER.NBA_SETTINGS_DRAWER_TITLE'})}
          </Button>
        </div>
        {/* <TabWrapper items={['NBA Settings', 'NBA Details']} selectedIndex={0} onUpdate={() => {}} /> */}
        <div className="tab-container  ml-8">
          <NBASettings
            status={status}
            isPublishedOrCompleted={isPublishedOrCompleted}
            isNew={isNewNBA}
            onNameChanged={onNameChanged}
            onDescriptionChanged={onDescriptionChanged}
            onPeriodDidChange={onPeriodDidChange}
            onAddLanguage={onAddLanguage}
            onChangeAward={onChangeAward}
            onChangeRewardType={onChangeRewardType}
            onFixRewardPointsChanged={onFixRewardPointsChanged}
            onThresholdChanged={onThresholdChanged}
            onThresholdFileUpdate={onThresholdFileUpdate}
            onRewardFileUpdate={onRewardFileUpdate}
            onFixThresholdChanged={onFixThresholdChanged}
            onCountryChanged={onCountryChanged}
            onCountryCodeChanged={onCountryCodeChanged}
            // onRecurrenceDidChange,
            // onTimeExecutionsDidChange,
            // onRecurrenceDaysDidChange,
            temporaryConfig={temporaryConfig}
            // onNBATypeDidChange,
            selectedDateInJsDate={selectedDateInJsDate}
            onDisplayPeriodDidChange={onDisplayPeriodDidChange}
            onAddTask={onAddTask}
            onDateDidChange={onDateDidChange}
            formErrors={formErrors}
          />
        </div>
      </div>
      <DrawerBottomButtons
        testIdPrimary="continue-or-save-button"
        testIdSecondary="cancel-button"
        isCancelButton
        onCancel={() => {
          if (!hasUnsavedChanges) {
            dispatch(setCancelPage())
            dispatch(
              setActiveNBA({
                ...temporaryConfig,
              })
            )
          }
          dispatch(closeNBADrawer())
        }}
        onClickPrimary={openNBAPreview}
        onClickSecondary={onSaveDraft}
        primaryButtonLabel={formatMessage({
          id: 'GENERAL.PREVIEW',
        })}
        secondaryButtonLabel={formatMessage({
          id: isUpdatingDraft ? 'GENERAL.SAVING_BUTTON' : 'GENERAL.SAVING_BUTTON_DRAFT',
        })}
        isPrimaryDisabled={!isAllRequiredFilled || !hasUnsavedChanges || !isFormError || isUpdatingDraft}
        isSecondaryDisabled={!isAllRequiredFilled || !isFormError || isUpdatingDraft}
        hasUnsavedChanges={!hasUnsavedChanges}
      />
    </div>
  )
}
