import {ErrorArray} from '@/components/NBAConfigEditor/FormFieldError'
import {LanguageSelect} from '@/components/Task/LanguageSelector'
import {useNBAMetaConfig} from '@/hooks/useNBAMetaConfig'
import {AddNewParameterButton} from '@/shared-components/AddNewParameterButton'
import {LanguageType} from '@/types/TaskSchema'
import {newLanguage} from '@ReduxActions'
import {useEffect, useRef} from 'react'
import {useIntl} from 'react-intl'

interface Props {
  position: number
  languageSelectors: Array<LanguageType>
  error: ErrorArray
  setLanguageSelectors: (newParams: Array<LanguageType>) => void
  taskId?: string | number
}

export const NBALanguageSelector: React.FC<Props> = ({
  languageSelectors,
  setLanguageSelectors,
  error,
  taskId,
  position,
}) => {
  const listRef = useRef<HTMLDivElement>(null)

  const {formatMessage} = useIntl()
  const {isPublishedOrCompleted} = useNBAMetaConfig()

  useEffect(() => {
    const updatedSelectors = languageSelectors.map(item => ({
      ...item,
      displayText: '',
    }))

    setLanguageSelectors([...updatedSelectors])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId])

  const addMoreLanguage = () => {
    if (isPublishedOrCompleted) return
    // const lastFilterSelector = taskSelectors[taskSelectors.length - 1]

    setLanguageSelectors([...languageSelectors, {...newLanguage}])

    setTimeout(() => {
      if (!listRef.current) return
      listRef.current.scrollTop = Number.MAX_SAFE_INTEGER
    }, 10)
  }

  const updateSelectedValues = (id: number, updatedTaskSelector: LanguageType | null) => {
    if (isPublishedOrCompleted) return
    const updatedSelectors = languageSelectors
      .map((item, key) => (id === key ? updatedTaskSelector : item))
      .filter((item): item is LanguageType => !!item)

    setLanguageSelectors([...updatedSelectors])
  }

  const hasMultipleTaskSelectors = languageSelectors.length > 1

  return (
    <div ref={listRef} className="mb-2 w-full">
      {languageSelectors.map((languageSelector, idx) => {
        const _error = error?.filter(item => item.path[3] == idx && item.code == 'custom')

        return (
          <LanguageSelect
            position={idx}
            taskPosition={position}
            isPublished={isPublishedOrCompleted}
            currentTaskSelector={languageSelector}
            hasMultipleTaskSelectors={hasMultipleTaskSelectors}
            key={idx}
            updateSelectedValues={updateSelectedValues}
            taskId={taskId}
            error={_error}
          />
        )
      })}
      {!isPublishedOrCompleted && (
        <AddNewParameterButton
          data-testid="add-new-time-execution-button"
          data-cy="add-new-time-execution-button"
          onClick={addMoreLanguage}
          className="mt-2 flex w-full max-w-[48.5%] px-3 text-xs font-medium"
        >
          {formatMessage({id: 'ENTRY_CONDITION_CONTENT.ADD_LANGUAGE_BUTTON'})}
        </AddNewParameterButton>
      )}
    </div>
  )
}
