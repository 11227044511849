import {LANGUAGES_QUERY_KEY} from '@/config/constants'
import {useAppDispatch} from '@/store'
import {setLanguagesByCID} from '@/store/slices/commonSlice'
import {GetLanguagesResponse, LanguagesType} from '@/types/LanguageResponse'
import {getLanguagesUseCase} from '@/useCases/getLanguagesUseCase'
import {useQuery} from '@tanstack/react-query'
import {useEffect} from 'react'

type LanguagesReturnTypes = {
  languagesData: GetLanguagesResponse | undefined
  isFetchingLanguages: boolean
}

type Props = {
  countryId: number
}
export const useLanguages = ({countryId}: Props) => {
  const dispatch = useAppDispatch()

  const {isFetching: isFetchingLanguages, data: languagesData} = useQuery({
    queryKey: [LANGUAGES_QUERY_KEY, countryId],
    queryFn: ({signal}: {signal: AbortSignal}) => getLanguagesUseCase(countryId, signal),
    retry: 1,
    enabled: countryId > 0,
    staleTime: 60 * 60 * 1000, // 1 hour
  })

  useEffect(() => {
    if (languagesData) {
      dispatch(setLanguagesByCID(languagesData?.content as LanguagesType))
    }
  }, [languagesData, dispatch])

  return {
    languagesData,
    isFetchingLanguages,
  } as LanguagesReturnTypes
}
