import {createNBAService} from '@/services/nbasService'
import {CreateNBARequest, CreateNBAResponse} from '@/types/NBAs'
import {getAxiosError} from '@/utils/errorUtils'
import {z} from 'zod'

export const ErrorResponseSchema = z.object({
  success: z.boolean(),
  errorMessage: z.string(),
})

export type ErrorResponse = z.infer<typeof ErrorResponseSchema> | Error

export async function createNBAUseCase(params: CreateNBARequest): Promise<CreateNBAResponse> {
  try {
    const response = await createNBAService(params)
    return response
  } catch (err) {
    throw getAxiosError(err)
  }
}
