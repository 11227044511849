import {TASK_TYPES_QUERY_KEY} from '@/config/constants'
// import {GetTaskTypesResponse} from '@/types/TaskTypesResponse'
import {getTaskTypesUseCase} from '@/useCases/getTaskTypesUseCase'
import {useQuery} from '@tanstack/react-query'

// type TaskTypeReturn = {
//   taskTypesData: GetTaskTypesResponse | undefined
//   isFetchingTaskTypes: boolean
// }

export const useTasktypes = () => {
  const {isFetching: isFetchingTaskTypes, data: taskTypesData} = useQuery({
    queryKey: [TASK_TYPES_QUERY_KEY],
    queryFn: ({signal}: {signal: AbortSignal}) => getTaskTypesUseCase(signal),
    retry: 1,
    staleTime: 60 * 60 * 1000, // 1 hour
  })

  return {
    taskTypesData,
    isFetchingTaskTypes,
  }
}
