import {useNBAMetaConfig} from '@/hooks/useNBAMetaConfig'
import {uploadFileService} from '@/services/nbasService'
import {useAppDispatch} from '@/store'
import {FileUploadResponse} from '@/types/upload'
import {deleteFileUseCase} from '@/useCases/deleteFileUseCase'
import {setActiveNBA, showNotification} from '@ReduxActions'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {format} from 'date-fns'
import {useState} from 'react'
import {useIntl} from 'react-intl'

type FileParams = {
  fileType?: string
  taskId?: string
}

export const useFileUpload = () => {
  const [isUpdating, setUpdating] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)
  const [fileUploadError, setFileUploadError] = useState<string>('')
  const {id: nbaId} = useNBAMetaConfig()
  const dispatch = useAppDispatch()
  const {formatMessage} = useIntl()

  const {mutate, isPending: isDeleteFile} = useMutation({
    mutationKey: ['deleteFile'],
    mutationFn: deleteFileUseCase,
    // mutationFn: createNBAUseCase,
    onSuccess: data => {
      // dispatch(
      //   showNotification({
      //     show: true,
      //     type: 'success',
      //     title: 'NBA created',
      //     message: 'NBA created successfully',
      //   })
      // )
      setFileUploadError('')
      console.log('Notification created successfully:', data)
    },
    onError: error => {
      dispatch(
        showNotification({
          show: true,
          type: 'error',
          title: 'File Delete',
          message: error.message,
        })
      )
      console.error('Error creating notification:', error)
    },
  })

  const onDeleteFile = (fileName: string, filePath: string) => {
    if (nbaId) mutate({nbaId, fileName, filePath})
  }

  const onUploadFile = (file: File, onCallback: (response: FileUploadResponse) => void, params: FileParams = {}) => {
    const formData = new FormData()
    const {fileType, taskId} = params
    const dateTime = format(new Date(), 'ddMMMyyyy_hhmmssa')
    const fileNameSplit = file.name.split('.')

    // Rename the file <taskTypeName>_filename_<dateTime>.<fileformat>
    const newFileName = `${fileType}_${fileNameSplit.slice(0, -1).join('.')}_${dateTime}.${fileNameSplit.pop()}`
    const renamedFile = new window.File([file], newFileName, {type: file.type})

    formData.append('file', renamedFile)
    if (!fileType) {
      setFileUploadError(formatMessage({id: 'ERRORS.FILETYPE_REUIRED'}))
      return
    }
    formData.append('fileType', fileType)
    if (nbaId) {
      formData.append('nbaId', nbaId)
    }
    if (taskId) {
      formData.append('taskId', taskId)
    } else {
      formData.append('taskId', '1')
    }
    setUpdating(true)
    uploadFileService(formData, (progressEvent: ProgressEvent) => {
      const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      setProgress(progress)

      // console.log(progressEvent)
    })
      .then(response => {
        dispatch(setActiveNBA({id: response.nbaId}))
        onCallback(response)
        dispatch(setActiveNBA({id: response.nbaId}))
        setUpdating(false)
        setProgress(0)
        setFileUploadError('')
      })
      .catch(error => {
        const errMsg =
          (error instanceof AxiosError && error.response?.data.errorMessage) ||
          (error as Error).message ||
          'An unknown error occurred'
        setUpdating(false)
        setProgress(0)
        setFileUploadError(errMsg)
      })
  }

  return {
    onUploadFile,
    isUpdating,
    progress,
    fileUploadError,
    setFileUploadError,
    isDeleteFile,
    onDeleteFile,
  }
}
