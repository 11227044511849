import {ErrorArray} from '@/components/NBAConfigEditor/FormFieldError'
import {useNBAMetaConfig} from '@/hooks/useNBAMetaConfig'
import {useAppDispatch} from '@/store'
import {refetchNBA} from '@/store/slices/commonSlice'
import {setUpdateContentByid} from '@/store/slices/nbaListSlice'
import {RecurrenceDays} from '@/types/Day'
import {nbaFormValidationSchema} from '@/types/NBARequestSchema'
import {CreateNBARequest} from '@/types/NBAs'
import {NBAStatus, NBAStatusList} from '@/types/NBAStatus'
import {NBAType} from '@/types/NBAType'
import {Recurrence} from '@/types/Recurrence'
import {RewardTypeValues} from '@/types/rewardType'
import {LanguageType, TaskType} from '@/types/TaskSchema'
import {ThresholdTypeValues} from '@/types/threshholdType'
import {TimeExecutionWindows} from '@/types/TimeExecution'
import {createNBAUseCase} from '@/useCases/createNBAUseCase'
import {updateNBAUseCase} from '@/useCases/updateNBAUseCase'
import {
  NBAConfigEditableFields,
  openNBADrawer,
  setActiveNBA,
  setCopyNBAConfig,
  setCountry,
  setHasUnsavedChanges,
  showNotification,
} from '@ReduxActions'
import {useMutation} from '@tanstack/react-query'
import {format} from 'date-fns'
import _ from 'lodash'
import {DateTime} from 'luxon'
import {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'

const payloadDateFormatPattern = 'yyyy-MM-dd'

export const useNBAConfigEditor = () => {
  const dispatch = useAppDispatch()
  const {formatMessage} = useIntl()
  const {...savedConfig} = _.omit(useNBAMetaConfig(), ['status', 'isNew', 'id', 'copyNBAConfig'])
  const {id: nbaId} = useNBAMetaConfig()

  const [temporaryConfig, setTemporaryConfig] = useState<NBAConfigEditableFields>(savedConfig)

  const hasUnsavedChanges = useMemo(() => _.isEqual(temporaryConfig, savedConfig), [savedConfig, temporaryConfig])

  useEffect(() => {
    dispatch(setHasUnsavedChanges(hasUnsavedChanges))
  }, [hasUnsavedChanges, dispatch])
  useEffect(() => {
    dispatch(setCopyNBAConfig(JSON.stringify(temporaryConfig)))
  }, [temporaryConfig, dispatch])

  const isAllRequiredFilled = useMemo(() => {
    return !!temporaryConfig.name && !!temporaryConfig.countryId
  }, [temporaryConfig.countryId, temporaryConfig.name])

  const {mutate, isPending: isUpdatingDraft} = useMutation({
    mutationKey: [nbaId ? 'updateNBA' : 'createNBA'],
    mutationFn: nbaId ? updateNBAUseCase : createNBAUseCase,
    // mutationFn: createNBAUseCase,
    onSuccess: data => {
      if (data.success && data.data) {
        dispatch(
          setUpdateContentByid({
            ...data.data,
          })
        )
        dispatch(refetchNBA(true))
        dispatch(
          showNotification({
            show: true,
            type: 'success',
            title: 'NBA created',
            message: 'NBA saved successfully',
          })
        )
      } else {
        dispatch(
          showNotification({
            show: true,
            type: 'error',
            title: 'NBA created',
            message: String(data.errorMessage),
          })
        )
      }

      console.log('Notification created successfully:', data)
    },
    onError: error => {
      dispatch(
        showNotification({
          show: true,
          type: 'error',
          title: 'NBA created',
          message: error.message,
        })
      )
    },
  })

  const onNameChanged = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    setTemporaryConfig(prevConfig => ({...prevConfig, name: evt.target.value}))
  }, [])

  const onDescriptionChanged = useCallback((evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTemporaryConfig(prevConfig => ({...prevConfig, description: evt.target.value}))
  }, [])

  const onCountryChanged = useCallback(
    ({countryId, countryCode}: {countryId: number; countryCode: string}) => {
      setTemporaryConfig(prevConfig => ({
        ...prevConfig,
        countryId: countryId,
        countryCode: countryCode,
        country: countryCode,
      }))
      dispatch(setCountry({countryId, countryCode}))
    },
    [dispatch]
  )
  // const onCountryChanged = useCallback((value: number) => {
  //   setTemporaryConfig(prevConfig => ({...prevConfig, countryId: value}))
  // }, [])
  const onCountryCodeChanged = useCallback((value: string) => {
    setTemporaryConfig(prevConfig => ({...prevConfig, countryCode: value}))
  }, [])
  const onPeriodDidChange = useCallback((start?: string, end?: string) => {
    const period = start || end ? {start, end} : undefined
    setTemporaryConfig(prevConfig => ({
      ...prevConfig,
      period,
    }))
  }, [])

  const onNBATypeDidChange = useCallback((value: NBAType) => {
    setTemporaryConfig(prevConfig => ({
      ...prevConfig,
      type: value,
    }))
  }, [])

  const onRecurrenceDidChange = useCallback((value?: Recurrence) => {
    setTemporaryConfig(prevConfig => ({
      ...prevConfig,
      recurrence: value,
    }))
  }, [])

  const onRecurrenceDaysDidChange = useCallback((value: RecurrenceDays) => {
    setTemporaryConfig(prevConfig => ({
      ...prevConfig,
      recurrenceDays: value,
    }))
  }, [])

  const onTimeExecutionsDidChange = useCallback((value?: TimeExecutionWindows) => {
    setTemporaryConfig(prevConfig => ({
      ...prevConfig,
      timeExecutionWindows: value,
    }))
  }, [])

  // const onNBATypeDispatch = useCallback(() => {
  //   const {first} = canvasApp.getNodes()
  //   dispatch(setPeriod({start: temporaryConfig.period?.start, end: undefined}))
  //   dispatch(setRecurrence(undefined))
  //   dispatch(updateReEntryDays({id: first.getID(), reentryDays: null}))
  // }, [temporaryConfig.period, dispatch])

  const saveTemporaryChanges = useCallback(
    async (onDidSave?: () => void) => {
      const statusId = NBAStatusList.find(item => item.status === NBAStatus.draft)?.id
      const result = nbaFormValidationSchema.safeParse({...temporaryConfig, status: NBAStatus.draft, statusId})
      const postData = {
        ...result.data,
        startDate: temporaryConfig.period?.start,
        endDate: temporaryConfig.period?.end,
        displayStartDate: temporaryConfig.displayPeriod?.start,
        displayEndDate: temporaryConfig.displayPeriod?.end,
        onAwardDate: temporaryConfig.onAwardDate ? new Date(temporaryConfig.onAwardDate).toISOString() : '',
      } as CreateNBARequest

      if (nbaId) {
        postData['id'] = nbaId
      }
      await mutate(postData, {
        onSuccess: result => {
          if (result.success && result.data) {
            dispatch(
              setActiveNBA({
                ...temporaryConfig,
                id: result.data.id,
                status: result.data.status as NBAStatus,
              })
            )
            // dispatch(setPeriod({start: temporaryConfig.period?.start, end: temporaryConfig.period?.end}))
            // onNBATypeDispatch()
            onDidSave?.()
          }
        },
        onError: err => {
          console.log(err.message)
        },
      })
    },
    [dispatch, temporaryConfig, mutate, nbaId]
  )

  /** for changes {dominic george} */

  const onDisplayPeriodDidChange = useCallback((start?: string, end?: string) => {
    const displayPeriod = start || end ? {start, end} : undefined
    setTemporaryConfig(prevConfig => ({
      ...prevConfig,
      displayPeriod,
    }))
  }, [])

  const onAddTask = useCallback((tasks: Array<TaskType>) => {
    // const displayPeriod = start || end ? {start, end} : undefined

    setTemporaryConfig(prevConfig => ({
      ...prevConfig,
      tasks,
    }))
  }, [])

  const onChangeAward = useCallback((award: number) => {
    setTemporaryConfig(prevConfig => ({
      ...prevConfig,
      onTaskCompletion: award == 0 ? true : false,
    }))
    setTemporaryConfig(prevConfig => ({
      ...prevConfig,
      onAwardDate: '',
    }))
  }, [])

  const onChangePoints = useCallback((points: number) => {
    setTemporaryConfig(prevConfig => ({
      ...prevConfig,
      points,
    }))
  }, [])

  const onChangeRewardType = useCallback((value: number) => {
    setTemporaryConfig(prevConfig => ({...prevConfig, fixRewardPoints: ''}))

    setTemporaryConfig(prevConfig => ({
      ...prevConfig,
      fixReward: RewardTypeValues[value],
    }))
  }, [])

  const onFixRewardPointsChanged = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    setTemporaryConfig(prevConfig => ({...prevConfig, fixRewardPoints: evt.target.value.replace(/[^\w\s]/gi, '')}))
  }, [])

  const onRewardFileUpdate = (e: string) => {
    setTemporaryConfig(prevConfig => ({
      ...prevConfig,
      fixRewardPoints: e,
    }))
  }

  const onThresholdChanged = useCallback((threshold: number) => {
    setTemporaryConfig(prevConfig => ({
      ...prevConfig,
      fixThreshold: '',
    }))
    setTemporaryConfig(prevConfig => ({...prevConfig, treshhold: ThresholdTypeValues[threshold]}))
  }, [])

  const onFixThresholdChanged = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    setTemporaryConfig(prevConfig => ({...prevConfig, fixThreshold: evt.target.value.replace(/[^\w\s]/gi, '')}))
  }, [])

  const onThresholdFileUpdate = (e: string) => {
    setTemporaryConfig(prevConfig => ({
      ...prevConfig,
      fixThreshold: e,
    }))
  }
  const onAddLanguage = useCallback((languages: Array<LanguageType>) => {
    // const displayPeriod = start || end ? {start, end} : undefined
    setTemporaryConfig(prevConfig => ({
      ...prevConfig,
      languages,
    }))
  }, [])

  const onDateDidChange = (date?: Date) => {
    const newValue = date ? format(date.toString(), payloadDateFormatPattern) : ''
    // onChangeFilterValue({key: 'value', newValue})
    setTemporaryConfig(prevConfig => ({
      ...prevConfig,
      onAwardDate: newValue,
    }))
  }

  const openNBAPreview = useCallback(() => {
    dispatch(
      openNBADrawer({
        selectedPage: 'nbaPreview',
        title: formatMessage({id: 'NBA_BUILDER.NBA_PREVIEW_DRAWER_TITLE'}),
        canBeClosed: true,
      })
    )
  }, [dispatch, formatMessage])

  const selectedDateInJsDate = temporaryConfig.onAwardDate
    ? DateTime.fromFormat(temporaryConfig.onAwardDate, payloadDateFormatPattern).toJSDate()
    : undefined

  const validation = nbaFormValidationSchema.safeParse({...temporaryConfig, status: NBAStatus.draft, statusId: 1})
  const isFormError = validation.success

  return {
    onAddTask,
    onAddLanguage,
    onDateDidChange,
    onNameChanged,
    onDescriptionChanged,
    onCountryChanged,
    onCountryCodeChanged,
    onPeriodDidChange,
    onRecurrenceDidChange,
    onNBATypeDidChange,
    onTimeExecutionsDidChange,
    onDisplayPeriodDidChange,
    openNBAPreview,
    onChangeAward,
    onFixRewardPointsChanged,
    onChangePoints,
    onChangeRewardType,
    onThresholdChanged,
    onThresholdFileUpdate,
    onRewardFileUpdate,
    onFixThresholdChanged,
    savedConfig,
    temporaryConfig,
    saveTemporaryChanges,
    hasUnsavedChanges,
    isAllRequiredFilled,
    isFormError,
    selectedDateInJsDate,
    onRecurrenceDaysDidChange,
    isUpdatingDraft,
    formErrors: validation.error?.errors as ErrorArray,
  }
}
