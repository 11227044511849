import {getNBAService} from '../services/nbasService'
import {GetNBAResponse} from '../types/NBAs'

export type GetNBAUseCaseParams = {
  id?: string
}

export async function getNBAUseCase(
  {id}: GetNBAUseCaseParams,
  signal?: AbortSignal
): Promise<GetNBAResponse | undefined> {
  if (!id) return

  return getNBAService(id, signal)
}
