import {CreateNBARequestSchema} from '@/types/NBAs'
import {PeriodSchema} from '@/types/Period'
import {RewardTypeSchema} from '@/types/rewardType'
import {LanguageSchema, TaskSchema} from '@/types/TaskSchema'
import {ThresholdTypeSchema} from '@/types/threshholdType'
import {z as Zod} from 'zod'

export function hasDuplicates(array: Array<string>) {
  return new Set(array).size !== array.length
}
export function findIndexArrayDuplicates(array: Array<string>) {
  return array.reduce((acc, item, index) => {
    if (array.indexOf(item) !== index && !acc.includes(index)) {
      acc.push(index)
    }
    return acc
  }, [] as number[])
}

export const LanguageSchemaExt = LanguageSchema.extend({
  displayText: Zod.string().refine(value => value != '', {
    path: ['displayText'],
    message: 'Required Field',
  }),
  languageId: Zod.string()
    .or(Zod.number())
    .refine(value => value != '', {
      path: ['languageId'],
      message: 'Required Field',
    }),
})
export const TaskValidationSchema = TaskSchema.extend({
  languages: Zod.array(LanguageSchemaExt).superRefine((val, ctx) => {
    const languages = (val && val.map(item => String(item.languageId))) || []
    if (hasDuplicates(languages)) {
      const indexes = findIndexArrayDuplicates(languages)
      for (const index of indexes) {
        ctx.addIssue({
          code: Zod.ZodIssueCode.custom,
          path: [index, 'languageId'],
          message: 'Same language cannot be repeated!',
        })
      }
    }
    // for (const index in val) {
    //   if (val[index].languageId == '' || val[index].languageId == undefined) {
    //     ctx.addIssue({
    //       code: Zod.ZodIssueCode.custom,
    //       path: [index, 'languageId'],
    //       message: 'Required Field',
    //     })
    //   }
    // }
  }),
  taskName: Zod.string().refine(value => value != '', {
    path: ['taskName'],
    message: 'Required Field',
  }),
  filePath: Zod.string().refine(value => value != '', {
    path: ['filePath'],
    message: 'Required Field',
  }),
})

export const nbaFormValidationSchema = CreateNBARequestSchema.extend({
  languages: Zod.array(LanguageSchemaExt).superRefine((val, ctx) => {
    const languages = (val && val.map(item => String(item.languageId))) || []
    if (hasDuplicates(languages)) {
      const indexes = findIndexArrayDuplicates(languages)
      for (const index of indexes) {
        ctx.addIssue({
          code: Zod.ZodIssueCode.custom,
          path: [index, 'languageId'],
          message: 'Same language cannot be repeated!',
        })
      }
    }
  }),
  period: PeriodSchema.optional(),
  displayPeriod: PeriodSchema.optional(),
  onAwardDate: Zod.string(),
  tasks: Zod.array(TaskValidationSchema),
})
  .refine(
    schema => {
      return !(schema.onTaskCompletion == false) || schema.onAwardDate != ''
    },
    {
      path: ['onAwardDate'],
      message: 'Required Field',
    }
  )
  .refine(
    schema => {
      return !(schema.fixThreshold == '')
    },
    {
      path: ['fixThreshold'],
      message: 'Required Field',
    }
  )
  .refine(
    schema => {
      return (
        (schema.treshhold == ThresholdTypeSchema.Values.FIX_THRESHHOLD &&
          Number.isInteger(parseInt(schema.fixThreshold))) ||
        schema.treshhold == ThresholdTypeSchema.Values.THRESHHOLD_FILE
      )
    },
    {
      path: ['fixThreshold'],
      message: ' Should be numeric',
    }
  )
  .refine(
    schema => {
      return !(schema.fixRewardPoints == '')
    },
    {
      path: ['fixRewardPoints'],
      message: 'Required Field',
    }
  )
  .refine(
    schema => {
      return (
        (schema.fixReward == RewardTypeSchema.Values.FIX_REWARD_POINTS &&
          Number.isInteger(parseInt(String(schema.fixRewardPoints)))) ||
        schema.fixReward == RewardTypeSchema.Values.REWARD_FILE
      )
    },
    {
      path: ['fixRewardPoints'],
      message: ' should be numeric',
    }
  )
  .refine(
    schema => {
      return !(schema.period?.start == undefined || schema.period?.end == undefined)
    },
    {
      path: ['period'],
      message: 'Required Field',
    }
  )
  .refine(
    schema => {
      return !(schema.displayPeriod?.start == undefined || schema.displayPeriod?.end == undefined)
    },
    {
      path: ['displayPeriod'],
      message: 'Required Field',
    }
  )
  .refine(
    schema => {
      return !(schema.name == '')
    },
    {
      path: ['name'],
      message: 'Required Field',
    }
  )

// export const nbaFormValidationSchema = Zod.object({
//   name: Zod.string(),
//   description: Zod.string(),
//   languages: Zod.array(LanguageSchema).optional()
//   .refine(schema => {
//     const languges = (schema && schema.map(item => String(item.languageId))) || []
//     return !hasDuplicates(languges)
//   }, 'Duplicate detection you selected language'),
//   countryId: Zod.string(),
//   countryCode: Zod.string(),
//   statusId: Zod.number(),
//   status: Zod.nativeEnum(NBAStatus),
//   period: PeriodSchema.optional(),
//   displayPeriod: PeriodSchema.optional(),
//   tasks: Zod.array(TaskValidationSchema).optional(),
//   awardAssignment: AwardTypeSchema,
//   onAwardDate: Zod.string().optional(),
//   rewardFixPoints: Zod.string().or(Zod.number()).optional(),
//   fixReward: RewardTypeSchema,
//   fixRewardPoints: Zod.string().optional(),
//   treshhold: ThresholdTypeSchema,
//   fixThreshold: Zod.string().or(Zod.number()).optional(),
// })

// import * as Zod from 'zod'

// export const nbaFormValidationSchema = Zod.object({
//   name: Zod.string().trim().min(1, {message: 'Required'}),
//   description: Zod.string().trim().min(1, {message: 'Required'}),
//   languages: Zod.array(
//     Zod.object({
//       language: Zod.string().trim().min(1, {message: 'Required'}),
//       name: Zod.string().trim().min(1, {message: 'Required'}),
//     }).required()
//   ),
//   country: Zod.string().trim().min(1, {message: 'Required'}),
//   period: Zod.object({
//     start: Zod.string().trim().min(1, {message: 'Required'}),
//     end: Zod.string().trim().min(1, {message: 'Required'}),
//   }).required(),
//   displayPeriod: Zod.object({
//     start: Zod.string().trim().min(1, {message: 'Required'}),
//     end: Zod.string().trim().min(1, {message: 'Required'}),
//   }).required(),
//   tasks: Zod.array(
//     Zod.object({
//       type: Zod.number(),
//       name: Zod.number(),
//       files: Zod.any().refine(value => {
//         if (value instanceof File) {
//           const allowedExtensions = ['csv', 'pdf']
//           const fileExtension: string | undefined = value.name.split('.').pop()
//           return allowedExtensions.includes(fileExtension as string)
//         }
//         return false
//       }, 'Please upload a CSV file'),
//       languages: Zod.array(
//         Zod.object({
//           language: Zod.string().trim().min(1, {message: 'Required'}),
//           displayName: Zod.string().trim().min(1, {message: 'Required'}),
//         })
//       ),
//     })
//   ),
//   awardAssignment: Zod.number(),
//   rewardFixPoints: Zod.number(),
//   fixNumber: Zod.number(),
//   taskThreshold: Zod.number(),
//   files: Zod.any().refine(value => {
//     if (value instanceof File) {
//       console.log(value)

//       const allowedExtensions = ['csv', 'pdf']
//       const fileExtension: string | undefined = value.name.split('.').pop()
//       return allowedExtensions.includes(fileExtension as string)
//     }
//     return false
//   }, 'Please upload a CSV file'),
//   awardPointsDate: Zod.string().trim().min(1, {message: 'Required'}),
// })
