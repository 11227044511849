import {ROUTES} from '@/config/constants'
import {useAppDispatch} from '@/store'
import {resetNBADrawer} from '@ReduxActions'
import {useCallback} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

export const useGoBack = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {state} = useLocation()

  const goBack = useCallback(() => {
    dispatch(resetNBADrawer())
    state?.prevPath ? navigate(state?.prevPath) : navigate(ROUTES.LIST_NBAS)
  }, [dispatch, state, navigate])

  return {
    goBack,
  }
}
