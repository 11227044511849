import {Button} from '@/shared-components/shadcn/button'
import {setActiveManualTask, setModal} from '@/store/slices/nbaManualTask'
import {useAppDispatch} from '@/store'
import {TaskType, TaskTypeNameEnum} from '@/types/TaskSchema'
import {useIntl} from 'react-intl'
import {NBAStatus} from '@/types/NBAStatus'
import React from 'react'
// import UploadedResults from '@/components/NBAConfigEditor/UploadedResults'

type TaskPreviewProps = {
  idx: number
  task: TaskType
  onClose?: () => void
  status: NBAStatus | null
}

type LabelValueComponentProps = {
  label: string
  value?: string | undefined
  link?: string | null
  children?: React.ReactNode
}

const LabelValueComponent: React.FC<LabelValueComponentProps> = ({label, value, link, children}) => (
  <div className="mt-2 flex flex-col border-t border-gray-100 pt-3">
    <label className="mb-1 text-xs">{label}</label>
    {link ? (
      <a href={link} className="text-primary" target="_blank" rel="noopener noreferrer">
        <p className="mb-1 break-all text-xs font-semibold">{value}</p>
      </a>
    ) : (
      value && <p className="mb-1 text-xs	font-semibold">{value}</p>
    )}

    {children}
  </div>
)

export const TaskPreview: React.FC<TaskPreviewProps> = ({idx, task, status, onClose}) => {
  const {formatMessage} = useIntl()
  const dispatch = useAppDispatch()

  const {languages = [], filePath = '', taskName, taskTypeName} = task
  //resultPath = []

  const isTaskManual = task.taskTypeName === ('Manual' as TaskTypeNameEnum)
  const showUploadResult = isTaskManual && status === NBAStatus.published

  const onUploadResultFile = (task: TaskType) => {
    onClose && onClose()
    dispatch(setModal(true))
    dispatch(setActiveManualTask({idx, task}))
  }

  return (
    <div className="rounded-lg border border-primary-100 p-4">
      <h3 className=" text-base	font-extrabold">{formatMessage({id: 'NBA_BUILDER.TASK_IDX'}, {idx})}</h3>
      <LabelValueComponent label={formatMessage({id: 'NBA_BUILDER.NBA_TASK_TYPE'})} value={taskTypeName as string} />
      <LabelValueComponent label={formatMessage({id: 'NBA_BUILDER.NBA_TASK_NAME'})} value={taskName} />

      {languages.map((language, index) => (
        <React.Fragment key={index}>
          <LabelValueComponent label={formatMessage({id: 'GENERAL.LANGUAGE_LABEL'})} value={language.languageName} />
          <LabelValueComponent
            label={formatMessage({id: 'NBA_BUILDER.TASK_DISPLAYNAME_LABEL'})}
            value={language.displayText}
          />
        </React.Fragment>
      ))}

      <LabelValueComponent
        label={formatMessage({id: 'NBA_BUILDER.STORE_FILE_LABEL'})}
        value={filePath?.slice(filePath?.lastIndexOf('/') + 1)}
        link={filePath}
      >
        {showUploadResult && (
          <>
            {/* <p className="mb-1 mt-3	text-xs">{formatMessage({id: 'NBA_BUILDER.RESULT_FILE'})}</p> */}
            <div className="flex flex-row justify-end	">
              {/* <UploadedResults resultPath={resultPath} /> */}

              <Button className="p-0 text-xs text-primary" variant="link" onClick={() => onUploadResultFile(task)}>
                {formatMessage({id: 'NBA_BUILDER.UPLOAD_RESULTS_LABEL'})}
              </Button>
            </div>
          </>
        )}
      </LabelValueComponent>
    </div>
  )
}
