import {ErrorArray} from '@/components/NBAConfigEditor/FormFieldError'
import {LanguageSelect} from '@/components/NBALanguage/LanguageSelector'
import {useNBAMetaConfig} from '@/hooks/useNBAMetaConfig'
import {AddNewParameterButton} from '@/shared-components/AddNewParameterButton'
import {LanguageType} from '@/types/TaskSchema'
import {newLanguage} from '@ReduxActions'
import {useRef} from 'react'
import {useIntl} from 'react-intl'

interface Props {
  languageSelectors: Array<LanguageType>
  error?: ErrorArray
  setLanguageSelectors: (newParams: Array<LanguageType>) => void
}

export const NBALanguage: React.FC<Props> = ({languageSelectors, setLanguageSelectors, error}) => {
  const listRef = useRef<HTMLDivElement>(null)

  const {formatMessage} = useIntl()
  const {isPublishedOrCompleted} = useNBAMetaConfig()

  const addMoreLanguage = () => {
    if (isPublishedOrCompleted) return
    // const lastFilterSelector = taskSelectors[taskSelectors.length - 1]

    setLanguageSelectors([...languageSelectors, {...newLanguage}])

    setTimeout(() => {
      if (!listRef.current) return
      listRef.current.scrollTop = Number.MAX_SAFE_INTEGER
    }, 10)
  }

  const updateSelectedValues = (id: number, updatedTaskSelector: LanguageType | null) => {
    if (isPublishedOrCompleted) return
    const updatedSelectors = languageSelectors
      .map((item, key) => (id === key ? updatedTaskSelector : item))
      .filter((item): item is LanguageType => !!item)

    setLanguageSelectors(updatedSelectors.length ? [...updatedSelectors] : languageSelectors)
  }

  const hasMultipleTaskSelectors = languageSelectors.length > 2

  return (
    <div ref={listRef} className="mb-2 w-full">
      {languageSelectors.map((languageSelector, idx) => {
        const _error = error?.filter(
          item => item.path[0] == 'languages' && item.path[1] == idx && item.code == 'custom'
        )

        return (
          <LanguageSelect
            position={idx}
            isPublished={isPublishedOrCompleted}
            currentTaskSelector={languageSelector}
            hasMultipleTaskSelectors={hasMultipleTaskSelectors}
            key={idx}
            error={_error}
            updateSelectedValues={updateSelectedValues}
          />
        )
      })}
      {!isPublishedOrCompleted && (
        <AddNewParameterButton
          data-testid="add-new-time-execution-button"
          data-cy="add-new-time-execution-button"
          onClick={addMoreLanguage}
          className="mt-2 flex w-full max-w-[48.5%]"
        >
          {formatMessage({id: 'ENTRY_CONDITION_CONTENT.ADD_LANGUAGE_BUTTON'})}
        </AddNewParameterButton>
      )}
    </div>
  )
}
