import {Radio} from 'pepsico-ds'

type Props = {
  className?: string
  items: Array<string>
  label?: string
  selectedItemIndex?: number
  onUpdate?: (e: number) => void
  title?: string
}

export const RadioButtonGroup: React.FC<Props> = ({className, ...rest}) => {
  return (
    <div className={className}>
      <Radio
        className="flex flex-row flex-wrap [&>.container]:flex-[1_0_50%] [&>.label]:flex-[0_1_100%] [&_.label-text_span]:hidden"
        reference="radio button"
        size="small"
        {...rest}
      />
    </div>
  )
}

RadioButtonGroup.defaultProps = {
  className: '',
  label: 'Label',
  items: ['Radio 1', 'Radio 2s'],
  onUpdate: () => {},
}
