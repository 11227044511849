import {NBAConfigEditor} from '@/components/NBAConfigEditor/NBAConfigEditor'
import {NBADetails} from '@/components/NBAConfigEditor/NBADetails'
import {NBAPreview} from '@/components/NBAConfigEditor/NBAPreview'
import {DrawerPages} from '@ReduxActions'
import React from 'react'

const pages: Record<DrawerPages, React.ReactNode> = {
  nbaConfigEditor: <NBAConfigEditor />,
  nbaPreview: <NBAPreview />,
  nbaDetails: <NBADetails />,
}

const NBADrawerPages: React.FC<{selectedPage?: DrawerPages}> = ({selectedPage}) => {
  return selectedPage ? (
    <div className="overflow-y-auto" data-testid="nba-drawer-pages">
      {pages[selectedPage]}
    </div>
  ) : null
}

export default NBADrawerPages
