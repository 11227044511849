import {Timezone, timezones} from '@/config/constants'
import {convertJsDateToDateTimeByTimezone, convertUtcStringToJsDate} from '@/helpers/dateUtils'
import {cn} from '@/helpers/shadcn/utils'
import {DatePicker} from '@/shared-components/shadcn/datepicker'
import {NBAType, NBATypeSchema} from '@/types/NBAType'
import {Period} from '@/types/Period'
import {DateTime} from 'luxon'
import {useMemo} from 'react'
import {useIntl} from 'react-intl'

export type DateRangeSelectorProps = Period & {
  onChange?: (start?: string, end?: string) => void
  disabled?: boolean
  timezone?: Timezone
  className?: string
  type?: NBAType
  showInfo?: boolean
  isStartDateRequired?: boolean
  isEndDateRequired?: boolean
  label?: string | undefined
  error?: string | undefined
}

export const DateRangeSelector = ({
  start,
  end,
  onChange,
  disabled = false,
  timezone = timezones.UTC,
  className,
  type,
  showInfo = true,
  isStartDateRequired = false,
  isEndDateRequired = false,
  label = undefined,
  error,
}: DateRangeSelectorProps) => {
  const {formatMessage} = useIntl()
  const _label = label ? label : formatMessage({id: 'ENTRY_CONDITION_CONTENT.DURATION'})
  const isOnetimeJourneyType = useMemo(() => type === NBATypeSchema.enum.ONE_TIME, [type])

  const systemNow = DateTime.now().minus({days: 1})
  const countryNow = systemNow.setZone(timezone)
  const todayInTimezone = DateTime.fromObject(countryNow.toObject()).toJSDate()

  const isStartDateDisabled = disabled
  const isEndDateDisabled = isStartDateDisabled || !start

  const onDateDidChange = (selectedStartDate?: Date, selectedEndDate?: Date) => {
    const selectedStartDateForTimezoneInUtc = convertJsDateToDateTimeByTimezone({date: selectedStartDate, timezone})
    const selectedEndDateForTimezoneInUtc = convertJsDateToDateTimeByTimezone({date: selectedEndDate, timezone})
    if (!selectedStartDateForTimezoneInUtc) {
      onChange?.(undefined, undefined)
      return
    }

    const isEndDateBeforeStartDate = selectedEndDateForTimezoneInUtc
      ? selectedEndDateForTimezoneInUtc.diff(selectedStartDateForTimezoneInUtc, 'days').days < 0
      : true
    const shouldResetEndDate = isEndDateBeforeStartDate || !selectedStartDate

    const startISOString = selectedStartDateForTimezoneInUtc.startOf('day').toUTC().toISO() ?? undefined
    const endISOString = shouldResetEndDate
      ? undefined
      : selectedEndDateForTimezoneInUtc?.endOf('day').toUTC().toISO() ?? undefined
    onChange?.(startISOString, endISOString)
  }

  const countryStartingDateAsJsDate = convertUtcStringToJsDate({date: start ?? undefined, timezone})
  const countryEndingDateAsJsDate = convertUtcStringToJsDate({date: end ?? undefined, timezone})

  const diffInDays = start ? DateTime.utc().startOf('day').diff(DateTime.fromISO(start), 'days').days : 0
  const nextDay = DateTime.fromJSDate(countryStartingDateAsJsDate ?? todayInTimezone)
    .plus({days: 0})
    .toJSDate()
  const minimumAllowedInitialEndDate = diffInDays >= 1 ? todayInTimezone : nextDay

  return (
    <div className={cn('flex  flex-col gap-2 text-text-secondary', className)}>
      <p className="text-sm font-bold">{_label}</p>
      <div className="flex w-full items-start gap-4" data-testid="date-range-selector">
        <DatePicker
          id="start-date"
          className={`max-w-xs ${error ? '[&>.picker-btn]:!border-red-500' : ''}`}
          title={formatMessage({id: 'ENTRY_CONDITION_CONTENT.START_DATE'})}
          placeholder={formatMessage({id: 'ENTRY_CONDITION_CONTENT.SELECT_DATE'})}
          info={showInfo ? formatMessage({id: 'ENTRY_CONDITION_CONTENT.DURATION_START_DATE_INFO'}) : undefined}
          date={countryStartingDateAsJsDate}
          setDate={value =>
            value
              ? onDateDidChange(value, countryEndingDateAsJsDate)
              : onDateDidChange(countryStartingDateAsJsDate, countryEndingDateAsJsDate)
          }
          startingDate={todayInTimezone}
          disabled={isStartDateDisabled}
          required={isStartDateRequired}
          error={error}
        />

        {!isOnetimeJourneyType && (
          <DatePicker
            id="end-date"
            className={`max-w-xs ${error ? '[&>.picker-btn]:!border-red-500' : ''}`}
            title={formatMessage({id: 'ENTRY_CONDITION_CONTENT.END_DATE'})}
            placeholder={formatMessage({id: 'ENTRY_CONDITION_CONTENT.SELECT_DATE'})}
            date={countryEndingDateAsJsDate}
            setDate={value =>
              value
                ? onDateDidChange(countryStartingDateAsJsDate, value)
                : onDateDidChange(countryStartingDateAsJsDate, countryEndingDateAsJsDate)
            }
            startingDate={minimumAllowedInitialEndDate}
            disabled={isEndDateDisabled}
            required={isEndDateRequired}
          />
        )}
      </div>
    </div>
  )
}
