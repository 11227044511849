import {DropdownItemType} from '@/types/DropdownItemType'
import {InputSelector, InputSelectorProps} from '@/shared-components/InputSelector/InputSelector'

export interface DropdownSelectorProps extends Omit<InputSelectorProps, 'value' | 'onChange'> {
  items: Array<DropdownItemType>
  value: Array<DropdownItemType> | undefined
  onChange: (e: Array<DropdownItemType>) => void
  loading?: boolean
}

export const DropdownSelector: React.FC<DropdownSelectorProps> = ({
  items,
  value,
  loading,
  onChange,
  disabled,
  ...props
}) => {
  return (
    <div className="flex w-full min-w-fit flex-col gap-2 [&_.dropdown-wrapper_.label_.label-text_span]:hidden  [&_.label]:mb-[.4rem]">
      <InputSelector
        {...props}
        onChange={e => {
          onChange && onChange(items.filter(item => item.id === e.target.value))
        }}
        disabled={loading || disabled}
        value={(value && value[0]?.id) || ''}
      >
        {value && (
          <option disabled value={''}>
            {loading ? 'Loading' : 'Select'}
          </option>
        )}
        {items.map((item: DropdownItemType, key: number) => (
          <option key={key} value={item.id}>
            {item.displayText}
          </option>
        ))}
      </InputSelector>
    </div>
  )
}
