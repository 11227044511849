import {ErrorArray} from '@/components/NBAConfigEditor/FormFieldError'
import {AxiosError} from 'axios'

export const getError = (formErrors: ErrorArray, name: string) => {
  return (
    formErrors
      ?.filter(item => item.path[0] == name)
      ?.map(item => item.message)
      .join(',') || undefined
  )
}

export const getAxiosError = (err: unknown) => {
  const error = {
    message:
      (err instanceof AxiosError && err.response?.data.errorMessage) ||
      (err as Error).message ||
      'An unknown error occurred',
  }
  throw error
}
