import {ErrorArray} from '@/components/NBAConfigEditor/FormFieldError'
import {DropdownSelector} from '@/shared-components/DropdownSelector'
import {InputText} from '@/shared-components/InputText'
import {Button} from '@/shared-components/shadcn/button'
import {useAppDispatch, useAppSelector} from '@/store'
import {DropdownItemType} from '@/types/DropdownItemType'
import {LanguageType} from '@/types/TaskSchema'
import {getError} from '@/utils/errorUtils'
import {setFirstLanguage} from '@ReduxActions'
import {ChangeEvent, useEffect, useRef} from 'react'
import {useIntl} from 'react-intl'

interface Props {
  position: number
  isPublished?: boolean
  hasMultipleTaskSelectors: boolean
  currentTaskSelector: LanguageType
  updateSelectedValues: (id: number, updatedParamSelector: LanguageType | null) => void
  error?: ErrorArray
}

export const LanguageSelect: React.FC<Props> = ({
  position,
  isPublished,
  currentTaskSelector,
  // hasMultipleTaskSelectors,
  updateSelectedValues,
  error,
}) => {
  const {formatMessage} = useIntl()

  const refLang = useRef<boolean | null>(null)

  const dispatch = useAppDispatch()

  const languageItems = useAppSelector(state => state?.common?.languagesByCID || [])
  const isEditMode = useAppSelector(state => state.common.editMode)

  const isEN = languageItems.find(item => item.code == 'EN')

  useEffect(() => {
    if (position == 0 && isEN && refLang.current == null) {
      const updatedSelector = {
        ...currentTaskSelector,
        languageId: isEN.id,
        languageCode: isEN.code,
        languageName: isEN.name,
      }
      updateSelectedValues(position, updatedSelector)
      if (!isEditMode) {
        dispatch(setFirstLanguage(updatedSelector))
      }
      refLang.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, languageItems, refLang, isEditMode])

  const onChangeLanguageValue = (e: Array<DropdownItemType>) => {
    const updatedSelector: LanguageType = {
      ...currentTaskSelector,
      languageId: e[0].id,
      languageCode: e[0].displayCode,
      languageName: e[0].displayText,
    }
    updateSelectedValues(position, updatedSelector)
  }
  const onChangeNameValue = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const updatedSelector: LanguageType = {
      ...currentTaskSelector,
      displayText: e.target.value,
    }
    updateSelectedValues(position, updatedSelector)
  }

  const removeCurrentSelector = () => {
    updateSelectedValues(position, null)
  }

  const isFirstParamSelector = position > 0
  const shouldDisplayRemoveButton = !isPublished && isFirstParamSelector
  const languages: Array<DropdownItemType> = languageItems
    .filter(item => {
      if (position != 0) {
        return item.code != 'EN'
      }
      return true
    })
    .map(item => ({
      displayText: item.name,
      displayCode: item.code,
      id: String(item.id),
      isBadge: false,
    }))

  const _error = error?.filter(
    item => item.path[0] == 'languages' && item.path[1] == position && item.path[2] == 'languageId'
  )
  return (
    <>
      <div className="relative flex w-full flex-row  items-start justify-between gap-4 py-2 pb-0">
        <div className="flex w-full  flex-col gap-2">
          <DropdownSelector
            label={formatMessage({id: 'SEND_NOTIFICATION_CONTENT.LANGUAGE_LABEL'})}
            required
            items={languages}
            onChange={onChangeLanguageValue}
            value={languages.filter(item => item.id == currentTaskSelector.languageId) || []}
            disabled={position == 0 && !!isEN}
            error={_error && getError(_error, 'languages')}
          />
        </div>
        <div className="flex w-full flex-col gap-2">
          <InputText
            type="text"
            placeholder={formatMessage({id: 'ENTRY_CONDITION_CONTENT.NBA_DISPLAY_NAME_PLACEHOLDER'})}
            label={formatMessage({id: 'ENTRY_CONDITION_CONTENT.NBA_DISPLAY_NAME'})}
            maxLength={50}
            value={currentTaskSelector.displayText}
            onChange={onChangeNameValue}
            required
          />
        </div>
      </div>
      <div className="float-right justify-end">
        {shouldDisplayRemoveButton && (
          <Button
            className="h-[22px] p-0 text-xs text-primary"
            size="sm"
            onClick={removeCurrentSelector}
            variant="link"
          >
            {formatMessage({id: 'ENTRY_CONDITION_CONTENT.REMOVE'})}
          </Button>
        )}
      </div>
    </>
  )
}
