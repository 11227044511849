import {publishNBAService, updateNBAService} from '@/services/nbasService'
import {CreateNBARequest, CreateNBAResponse, PublishNBARequest} from '@/types/NBAs'
import {getAxiosError} from '@/utils/errorUtils'
import {logEvent} from '@/utils/logger'

export async function updateNBAUseCase(params: CreateNBARequest): Promise<CreateNBAResponse> {
  try {
    const response = await updateNBAService(params)
    return response
  } catch (error) {
    logEvent.log('Error in createNotificationUseCase:', error)
    throw getAxiosError(error)
  }
}

export async function publishNBAUseCase(payload: PublishNBARequest): Promise<CreateNBAResponse> {
  try {
    const response = await publishNBAService(payload)
    return response
  } catch (error) {
    logEvent.log('Error in createNotificationUseCase:', error)
    throw getAxiosError(error)
  }
}
