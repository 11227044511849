import {Timezone, timezones} from '@/config/constants'
import {format} from 'date-fns'
import {DateTime} from 'luxon'

const getJsDateFromObject = (zoneDateTime: DateTime) => {
  const dateTime = zoneDateTime.toObject()

  if (!dateTime?.year || !dateTime?.month) return

  return new Date(dateTime.year, dateTime.month - 1, dateTime.day, dateTime.hour, dateTime.minute, dateTime.second)
}

/**
 * This function is used to format a UTC iso string to as human friendly text for specific country's timezone.
 */
export const formatPeriodDate = (date?: string | null, country?: number | string, formatStr: string = 'PPP') => {
  if (!date || !country) return ''

  const tz = timezones[country as unknown as keyof typeof timezones]

  const zoneDateTime = DateTime.fromISO(date, {zone: timezones.UTC}).setZone(tz)
  const dateAsObj = getJsDateFromObject(zoneDateTime)
  return dateAsObj ? format(dateAsObj, formatStr) : undefined
}

/*
 * This function is used to convert a UTC iso string to a local date object from a specific timezone.
 */
export const convertUtcStringToJsDate = ({date, timezone}: {date?: string; timezone: Timezone}) => {
  if (!date || !timezone) return

  const zoneDateTime = DateTime.fromISO(date, {zone: timezones.UTC}).setZone(timezone)
  return getJsDateFromObject(zoneDateTime)
}

/**
 * This function is used to convert a local JS Date object to a Luxon DateTime object in a specific timezone.
 */
export const convertJsDateToDateTimeByTimezone = ({date, timezone}: {date?: Date; timezone: Timezone}) => {
  if (!date) return

  const dateLocale = date.toLocaleDateString('en-US')
  return DateTime.fromFormat(dateLocale, 'M/d/yyyy', {zone: timezone})
}
