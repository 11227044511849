import {TASKS_QUERY_KEY} from '@/config/constants'
// import {GetTasksResponse} from '@/types/TasksResponse'
import {getTasksUseCase} from '@/useCases/getTasksUseCase'
import {useQuery} from '@tanstack/react-query'

type Props = {
  typeId: string | number
}
export const useTasks = ({typeId}: Props) => {
  const {isFetching: isFetchingTasks, data: tasksData} = useQuery({
    queryKey: [TASKS_QUERY_KEY, typeId],
    queryFn: ({signal}: {signal: AbortSignal}) => getTasksUseCase(typeId, signal),
    retry: 1,
    enabled: !!Number(typeId),
    staleTime: 60 * 60 * 1000, // 1 hour
  })

  return {
    tasksData,
    isFetchingTasks,
  }
}
