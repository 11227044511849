import {useNBAMetaConfig} from '@/hooks/useNBAMetaConfig'
import {useAppDispatch} from '@/store'
import {setPublishedContentByid} from '@/store/slices/nbaListSlice'
// import {CreateNBARequest} from '@/types/NBAs'
// import {NBAStatus, NBAStatusList} from '@/types/NBAStatus'
// import {createNBAUseCase} from '@/useCases/createNBAUseCase'
import {publishNBAUseCase} from '@/useCases/updateNBAUseCase'
import {closeNBADrawer, resetNBAConfig, showNotification} from '@ReduxActions'
import {useMutation} from '@tanstack/react-query'
import {useCallback} from 'react'
export const useNBAPreview = () => {
  const dispatch = useAppDispatch()
  const {id: nbaId} = useNBAMetaConfig()

  const {mutate, isPending} = useMutation({
    mutationKey: ['createNBA'],
    mutationFn: publishNBAUseCase,
    // mutationFn: createNBAUseCase,
    onSuccess: data => {
      dispatch(setPublishedContentByid({id: String(nbaId)}))
      dispatch(
        showNotification({
          show: true,
          type: 'success',
          title: 'NBA published',
          message: 'NBA published successfully',
        })
      )
      console.log('Notification published successfully:', data)
    },
    onError: error => {
      dispatch(
        showNotification({
          show: true,
          type: 'error',
          title: 'NBA created',
          message: error.message,
        })
      )
      console.error('Error creating notification:', error)
    },
  })

  const onPublish = useCallback(async () => {
    await mutate(
      {id: nbaId},
      {
        onSuccess: result => {
          console.log('NBA published successfully:', result)
          dispatch(closeNBADrawer())
          dispatch(resetNBAConfig())
        },
        onError: err => {
          console.log(err)
        },
      }
    )
  }, [mutate, nbaId, dispatch])

  return {
    onPublish,
    isPending,
  }
}
