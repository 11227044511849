import {Drawer} from '@/components/Drawer/Drawer'
import NBADrawerPages from '@/components/Drawer/NBADrawerPages'
import {useNBADrawer} from '@/components/Drawer/hooks/useNBADrawer'
import {useAppDispatch, useAppSelector} from '../../store'
import {Alert} from '@/shared-components/Alert'
import {useCallback} from 'react'
import {DrawerPagesTypeSchema, resetNotifications, setActiveNBA} from '@ReduxActions'
import {setCancelPage} from '@/store/slices/commonSlice'

const NBADrawer = () => {
  const {isOpen, selectedPage, title} = useAppSelector(state => state.nbaDrawer)
  const hasUnsavedChanges = useAppSelector(state => state.hasUnsavedChanges)
  const copyNBAConfig = useAppSelector(state => state.nbaConfig.copyNBAConfig)
  const {onCloseDrawer} = useNBADrawer()
  const {show, type, message} = useAppSelector(state => state.notifications)
  const dispatch = useAppDispatch()

  const onCloseAlert = useCallback(() => {
    dispatch(resetNotifications())
  }, [dispatch])

  return (
    <Drawer
      data-testid="nba-drawer-modal"
      headerTitle={title}
      isOpen={isOpen}
      onClose={() => {
        if (!hasUnsavedChanges && selectedPage == DrawerPagesTypeSchema.Values.nbaConfigEditor) {
          dispatch(setCancelPage())
          if (copyNBAConfig)
            dispatch(
              setActiveNBA({
                ...(JSON.parse(copyNBAConfig as string) || {}),
              })
            )
        }
        onCloseDrawer()
      }}
    >
      <div className={`relative  [&>div]:left-[8px] [&>div]:top-[0px] ${show ? 'min-h-[60px]' : ''}`}>
        <Alert type={type} message={message} show={show} onCloseClick={onCloseAlert} />
      </div>
      <NBADrawerPages selectedPage={selectedPage} />
    </Drawer>
  )
}

export default NBADrawer
