import DrawerBottomButtons from '@/components/Drawer/ContentParams/DrawerBottomButtons'
import {TaskPreview} from '@/components/NBAConfigEditor/TaskPreview'
import {useNBAPreview} from '@/components/NBAConfigEditor/useNBAPreview.hook'
import {DateCell, PeriodCell} from '@/components/NBAsListTable/components/CustomCells'
import {Button} from '@/shared-components/shadcn/button'
import {useAppDispatch, useAppSelector} from '@/store'
import {openNBADrawer} from '@ReduxActions'
import {useIntl} from 'react-intl'

const RowComponent = ({
  label,
  value,
  children,
  link,
}: {
  label: string
  value?: string | number | undefined
  children?: React.ReactNode
  link?: string | null
}) => (
  <div className="mt-2 flex flex-col border-b border-gray-100 pb-2">
    <label className="mb-1 text-xs">{label}</label>
    {link ? (
      <a href={link} className="text-primary" target="_blank" rel="noopener noreferrer">
        <p className="mb-1 break-all text-xs font-semibold">{value}</p>
      </a>
    ) : (
      value && <p className="mb-1 text-xs font-semibold">{value}</p>
    )}
    {children}
  </div>
)

export const NBAPreview = () => {
  const {formatMessage} = useIntl()

  const dispatch = useAppDispatch()
  const nbaConfig = useAppSelector(state => state.nbaConfig)
  const {countries, editMode} = useAppSelector(state => state.common)
  const {onPublish, isPending} = useNBAPreview()

  const {
    countryCode,
    name,
    description,
    status,
    languages = [],
    period,
    displayPeriod,
    fixRewardPoints,
    fixThreshold,
    onAwardDate,
    tasks = [],
  } = nbaConfig

  const start = period?.start
  const end = period?.end
  const displayStartDate = displayPeriod?.start
  const displayEndDate = displayPeriod?.end

  const country = countries.find(c => c.code === countryCode)

  const isFixRewardFile = typeof fixRewardPoints === 'string' && isNaN(Number(fixRewardPoints))
  const isFixThresholdFile = typeof fixThreshold === 'string' && isNaN(Number(fixThreshold))

  return (
    <div className="mb-16 flex flex-col gap-6" data-testid="nba-config-editor">
      <div className="flex flex-row">
        <div className="flex flex-col pl-2">
          <Button
            className="mb-2 flex border border-auxiliary-100	bg-[transperent] px-14	text-xs text-primary"
            variant="outline"
          >
            {formatMessage({id: 'GENERAL.PREVIEW'})}
          </Button>
        </div>
        <div className="tab-container ml-8 w-[320px]">
          <p className="mb-2 border-b border-gray-100 pb-3 text-xs">
            Please review the preview and then click on publish.
          </p>
          <RowComponent label="Country" value={country?.name} />
          {languages?.map(language => {
            return (
              <>
                <RowComponent label="Language" value={language?.languageName} />
                <RowComponent label="NBA Display Name" value={language?.displayText} />
              </>
            )
          })}
          <RowComponent label="NBA Name" value={name} />
          {description && <RowComponent label="NBA Description" value={description} />}
          <RowComponent label="Duration">
            <PeriodCell className="text-xs font-semibold" value={{start, end}} />
          </RowComponent>
          <RowComponent label="Duration">
            <PeriodCell className="text-xs font-semibold" value={{start: displayStartDate, end: displayEndDate}} />
          </RowComponent>
          <RowComponent label="Display Duration" value={name} />
          <div className="my-6 grid grid-cols-1 gap-2">
            {tasks.map((item, key) => (
              <TaskPreview status={status} idx={key + 1} task={item} />
            ))}
          </div>
          <RowComponent
            label={formatMessage({
              id: isFixRewardFile
                ? 'ENTRY_CONDITION_CONTENT.REWARD_FILES'
                : 'ENTRY_CONDITION_CONTENT.REWARD_FIX_POINTS',
            })}
            link={isFixRewardFile ? fixRewardPoints : null}
            value={isFixRewardFile ? fixRewardPoints.split('/').pop() : fixRewardPoints}
          />
          <RowComponent
            label={formatMessage({
              id: isFixThresholdFile
                ? 'ENTRY_CONDITION_CONTENT.TASK_FIX_THRESHOLDS_FILE'
                : 'ENTRY_CONDITION_CONTENT.TASK_FIX_THRESHOLDS_NO',
            })}
            link={isFixThresholdFile ? fixThreshold : null}
            value={isFixThresholdFile ? fixThreshold : fixThreshold}
          />
          <RowComponent label={formatMessage({id: 'On Assigned Task Completion'})}>
            <DateCell className="text-xs font-semibold" value={onAwardDate} />
          </RowComponent>
        </div>
      </div>
      <DrawerBottomButtons
        testIdPrimary="continue-or-save-button"
        testIdSecondary="cancel-button"
        onClickPrimary={onPublish}
        onClickSecondary={() => {
          dispatch(
            openNBADrawer({
              selectedPage: 'nbaConfigEditor',
              title: formatMessage({id: 'NBA_BUILDER.CREATE_HEADER'}),
              canBeClosed: true,
            })
          )
        }}
        onCancel={() => {
          dispatch(
            openNBADrawer({
              selectedPage: 'nbaConfigEditor',
              title: formatMessage({id: 'NBA_BUILDER.CREATE_HEADER'}),
              canBeClosed: true,
            })
          )
        }}
        primaryButtonLabel={formatMessage({
          id: isPending ? 'GENERAL.PUBLISHING' : 'GENERAL.PUBLISH',
        })}
        secondaryButtonLabel={formatMessage({
          id: editMode ? 'NBA_BUILDER.NBA_DETAILS_ACTION_EDIT' : 'GENERAL.SAVING_BUTTON_DRAFT',
        })}
        isPrimaryDisabled={isPending}
        {...(!editMode ? {isCancelButton: true, isSecondaryDisabled: true} : {})}
      />
    </div>
  )
}
