import {ErrorArray} from '@/components/NBAConfigEditor/FormFieldError'
import {TaskSelect} from '@/components/Task/TaskSelector'
import {useNBAMetaConfig} from '@/hooks/useNBAMetaConfig'
import {AddNewParameterButton} from '@/shared-components/AddNewParameterButton'
import {TaskType} from '@/types/TaskSchema'
import {newLanguage} from '@ReduxActions'
import {useRef} from 'react'
import {useIntl} from 'react-intl'

interface Props {
  taskSelectors: Array<TaskType>
  error?: ErrorArray
  setTaskSelectors: (newParams: Array<TaskType>) => void
}

export const Task: React.FC<Props> = ({taskSelectors, setTaskSelectors, error}) => {
  const listRef = useRef<HTMLDivElement>(null)

  const {formatMessage} = useIntl()
  const {isPublishedOrCompleted} = useNBAMetaConfig()

  const addNewTask = () => {
    if (isPublishedOrCompleted) return
    // const lastFilterSelector = taskSelectors[taskSelectors.length - 1]

    setTaskSelectors([
      ...taskSelectors,
      {
        taskTypeId: undefined,
        taskTypeName: undefined,
        taskId: undefined,
        taskName: undefined,
        filePath: undefined,
        languages: [newLanguage],
      },
    ])

    setTimeout(() => {
      if (!listRef.current) return
      listRef.current.scrollTop = Number.MAX_SAFE_INTEGER
    }, 10)
  }

  const updateSelectedValues = (id: number, updatedTaskSelector: TaskType | null) => {
    const updatedSelectors = taskSelectors
      .map((item, key) => (id === key ? updatedTaskSelector : item))
      .filter(item => !!item) as TaskType[]

    setTaskSelectors([...updatedSelectors])
  }

  return (
    <div ref={listRef} data-testid="params-filter-card" className="w-full" data-cy="params-filter-card">
      {taskSelectors.map((taskSelector, idx) => {
        const _error = error?.filter(item => item.path[0] == 'tasks' && item.path[1] == idx) || []
        return (
          <div
            className="mb-4 w-full max-w-full rounded-lg border-2 border-dashed border-blue bg-layer-02 p-4 transition-all duration-300 hover:border-layer-03"
            key={idx}
          >
            <TaskSelect
              position={idx}
              // isPublished={isPublishedOrCompleted}
              error={_error}
              currentTaskSelector={taskSelector}
              key={idx}
              updateSelectedValues={updateSelectedValues}
            />
          </div>
        )
      })}
      {!isPublishedOrCompleted && (
        <AddNewParameterButton
          data-testid="add-filter-button"
          data-cy="add-filter-button"
          className="max-w-[48.5%]"
          onClick={addNewTask}
        >
          {formatMessage({id: 'ENTRY_CONDITION_CONTENT.ADD_NEW_TASK'})}
        </AddNewParameterButton>
      )}
    </div>
  )
}
