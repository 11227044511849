import {ErrorArray} from '@/components/NBAConfigEditor/FormFieldError'
import {useSuggestions} from '@/components/Task/useSuggestions'
import {DropdownSelector} from '@/shared-components/DropdownSelector'
import {InputText} from '@/shared-components/InputText'
import {Button} from '@/shared-components/shadcn/button'
import {useAppDispatch, useAppSelector} from '@/store'
import {DropdownItemType} from '@/types/DropdownItemType'
import {LanguageType} from '@/types/TaskSchema'
import {TEXT_TAG_ACTIVATION_CHAR} from '@/utils/consts'
import {getError} from '@/utils/errorUtils'
import {setFirstTaskLanguage} from '@ReduxActions'
import {FilterDropdownMenuList} from 'pepsico-ds'
import {ChangeEvent, KeyboardEvent, useEffect, useMemo, useRef, useState} from 'react'
import {useIntl} from 'react-intl'

interface Props {
  position: number
  taskPosition: number
  isPublished?: boolean
  hasMultipleTaskSelectors: boolean
  currentTaskSelector: LanguageType
  updateSelectedValues: (id: number, updatedParamSelector: LanguageType | null) => void
  taskId?: string | number
  error?: ErrorArray
}

export const LanguageSelect: React.FC<Props> = ({
  position,
  taskPosition,
  isPublished,
  currentTaskSelector,
  // hasMultipleTaskSelectors,
  updateSelectedValues,
  taskId,
  error,
}) => {
  const {formatMessage} = useIntl()
  const dispatch = useAppDispatch()
  const isEditMode = useAppSelector(state => state.common.editMode)
  const refLang = useRef<boolean | null>(null)
  const {suggestionsData} = useSuggestions(taskId)

  const onChangeLanguageValue = (e: Array<DropdownItemType>) => {
    const updatedSelector = {
      ...currentTaskSelector,
      languageId: e[0].id,
      languageName: e[0].displayText,
      languageCode: e[0].displayCode,
    }
    updateSelectedValues(position, updatedSelector)
  }

  const languageItems = useAppSelector(state => state?.common?.languagesByCID || [])
  const isEN = languageItems.find(item => item.code == 'EN')

  useEffect(() => {
    if (position == 0 && isEN && refLang.current == null) {
      setTimeout(() => {
        const updatedSelector = {
          ...currentTaskSelector,
          languageId: isEN.id,
          languageName: isEN.name,
          languageCode: isEN.code,
        }
        updateSelectedValues(position, updatedSelector)
        if (taskPosition == 0 && !isEditMode) {
          dispatch(setFirstTaskLanguage(updatedSelector))
        }
      })

      refLang.current = true
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, languageItems, isEN, refLang, taskPosition, isEditMode])

  //   const removeCurrentSelector = () => {
  //     updateSelectedParam(position, null)
  //   }

  //   const isFirstParamSelector = position === 0
  //   const shouldDisplayRemoveButton = !isPublished && (hasMultipleTaskSelectors || isFirstParamSelector)
  const languages: Array<DropdownItemType> = languageItems
    .filter(item => {
      if (position != 0) {
        return item.code != 'EN'
      }
      return true
    })
    .map(item => ({
      displayText: item.name,
      displayCode: item.code,
      id: String(item.id),
      isBadge: false,
    }))

  /** popover */

  const inputFieldRef = useRef<HTMLInputElement>(null)
  // const setInputFieldRef = ()
  const [lastTagStartingPoint, setLastTagStartingPoint] = useState(0)
  const [presentSuggestions, setPresentSuggestions] = useState(false)

  const onChangeNameValue = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const updatedSelector = {
      ...currentTaskSelector,
      displayText: e.target.value,
    }
    const message = e.target.value
    const cursorPosition = Number(e.target.selectionStart)

    updateCursor(message, cursorPosition)
    updateSelectedValues(position, updatedSelector)
  }

  const updateCursor = (newMessage: string, position?: number) => {
    if (!position) {
      setPresentSuggestions(false)
      return
    }

    const shouldPresentSuggestions = newMessage[position - 1] === TEXT_TAG_ACTIVATION_CHAR
    if (shouldPresentSuggestions) {
      setLastTagStartingPoint(position)
    }
    setPresentSuggestions(shouldPresentSuggestions)
  }

  const suggestions = useMemo(() => {
    return suggestionsData?.content?.map(item => ({code: item.name, label: item.name})) || []
  }, [suggestionsData])

  const inputActionProps = {
    onChange: onChangeNameValue,
    // onBlur: () => setPresentSuggestions(false),
    onKeyDown: (evt: KeyboardEvent<HTMLInputElement | HTMLInputElement>) => {
      if (evt.key !== 'Escape') {
        return
      }
      setPresentSuggestions(false)
    },
  }

  const onClickSuggestion = (suggestion: string) => {
    const firstPartMessage = currentTaskSelector.displayText?.slice(0, lastTagStartingPoint) || ''
    const secondPartMessage =
      currentTaskSelector.displayText?.slice(lastTagStartingPoint, currentTaskSelector.displayText?.length) || ''
    const newMessage = `${firstPartMessage}{${suggestion}} ${secondPartMessage}`

    setPresentSuggestions(false)
    const updatedSelector: LanguageType = {
      ...currentTaskSelector,
      displayText: newMessage,
    }
    updateSelectedValues(position, updatedSelector)

    inputFieldRef.current?.focus()
  }
  const removeCurrentSelector = () => {
    updateSelectedValues(position, null)
  }
  const isFirstParamSelector = position > 0
  const shouldDisplayRemoveButton = !isPublished && isFirstParamSelector

  const _error = error?.filter(item => item.path[4] == 'languageId')
  return (
    <>
      <div className="flex w-full max-w-lg flex-row gap-4 py-2 ">
        <div className="flex w-full  flex-col gap-1">
          <DropdownSelector
            className="font-normal"
            label={formatMessage({id: 'SEND_NOTIFICATION_CONTENT.LANGUAGE_LABEL'})}
            required
            items={languages}
            onChange={onChangeLanguageValue}
            value={languages.filter(item => item.id == currentTaskSelector.languageId) || []}
            disabled={position == 0 && !!isEN}
            error={_error && getError(_error, 'tasks')}
          />
        </div>
        <div className="relative flex w-full flex-col gap-1">
          <InputText
            type="text"
            innerRef={inputFieldRef}
            placeholder={formatMessage({id: 'NBA_BUILDER.NBA_TASK_DISPLAY_NAME'})}
            label={formatMessage({id: 'NBA_BUILDER.NBA_TASK_DISPLAY_NAME'})}
            maxLength={50}
            value={currentTaskSelector.displayText}
            required
            className="text-xs font-normal"
            disabled={!taskId}
            {...inputActionProps}
          />

          <SuggestionsPopOver
            suggestions={suggestions}
            inputFieldRef={inputFieldRef}
            lastTagStartingPoint={lastTagStartingPoint}
            visible={presentSuggestions}
            onSelect={onClickSuggestion}
            inputValue={String(currentTaskSelector.displayText)}
          />
        </div>
      </div>
      <div className="float-right justify-end">
        {shouldDisplayRemoveButton && (
          <Button
            className="h-[22px] p-0 text-xs text-primary"
            size="sm"
            onClick={removeCurrentSelector}
            variant="link"
          >
            {formatMessage({id: 'ENTRY_CONDITION_CONTENT.REMOVE'})}
          </Button>
        )}
      </div>
    </>
  )
}

type SuggestionsPopOverProps = {
  suggestions: Array<{code: string; label: string}>
  inputFieldRef: React.RefObject<HTMLInputElement>
  lastTagStartingPoint?: number
  visible?: boolean
  onSelect: (suggestion: string) => void
  inputValue: string
}

const SuggestionsPopOver = ({
  suggestions,
  inputFieldRef,
  lastTagStartingPoint = 0,
  visible = false,
  onSelect,
  inputValue,
}: SuggestionsPopOverProps) => {
  const [textWidth, setTextWidth] = useState(0)
  const [textHeight, setTextHeight] = useState(0)
  const inputLeftPos = inputFieldRef.current?.getBoundingClientRect().left ?? window.innerWidth / 2
  const inputTopPos = inputFieldRef.current?.getBoundingClientRect().top ?? window.innerHeight / 2
  console.log(inputTopPos, inputFieldRef.current?.getBoundingClientRect().top, 'inputTopPos')

  useEffect(() => {
    if (!inputFieldRef.current) return

    const inputElementAttr = window.getComputedStyle(inputFieldRef.current)
    const inputElementAttrFontSize = inputElementAttr.getPropertyValue('font-size')
    const inputElementAttrPadding = inputElementAttr.getPropertyValue('padding')
    const inputElementAttrWidth = inputElementAttr.getPropertyValue('width')

    const InputWidthWithoutPadding =
      Number(inputElementAttrWidth.slice(0, -2)) - 2 * Number(inputElementAttrPadding.slice(0, -2))

    const textValue = inputValue.slice(0, lastTagStartingPoint)

    const tempElement = document.createElement('span')
    tempElement.textContent = textValue
    tempElement.style.visibility = 'hidden'
    tempElement.style.whiteSpace = 'nowrap'
    tempElement.style.position = 'absolute'
    tempElement.style.fontSize = inputElementAttrFontSize

    document.body.appendChild(tempElement)
    setTextWidth(tempElement.offsetWidth % InputWidthWithoutPadding)
    document.body.removeChild(tempElement)

    const tempForHeightElement = document.createElement('span')
    tempForHeightElement.textContent = textValue || '0'
    tempForHeightElement.style.visibility = 'hidden'
    tempForHeightElement.style.position = 'absolute'
    tempForHeightElement.style.fontSize = inputElementAttrFontSize
    tempForHeightElement.style.width = inputElementAttrWidth
    tempForHeightElement.style.whiteSpace = 'break-spaces'
    tempForHeightElement.style.padding = inputElementAttrPadding

    document.body.appendChild(tempForHeightElement)
    setTextHeight(tempForHeightElement.offsetHeight)
    document.body.removeChild(tempForHeightElement)
  }, [inputValue, inputFieldRef, lastTagStartingPoint])

  // const topOffset = -200

  return (
    <div
      style={{
        left: `${inputLeftPos + 10 + textWidth}px`,
        top: `${inputTopPos + textHeight}px`,
        opacity: visible ? 1 : 0,
        visibility: visible ? 'visible' : 'hidden',
        transition: '0.2s all',
        zIndex: 999,
      }}
      data-testid="tags-suggestions-wrapper"
      className="fixed"
    >
      <FilterDropdownMenuList
        handleSelectId={item => onSelect(item)}
        options={suggestions.map(suggestion => ({
          id: suggestion.code,
          label: suggestion.label,
        }))}
        selection="single"
        className="w-full p-0"
      />
    </div>
  )
}
