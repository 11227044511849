import {Button} from '@/shared-components/shadcn/button'

export const JourneryAction = () => {
  return (
    <div className="mb-4 flex flex-col rounded-md bg-yellow-100 px-5 py-2">
      <h5 className="py-1 text-base font-bold">Take Action</h5>
      <p className="text-sm">Use recurring journey option to define notification before the NBA start date</p>
      <div className="flex w-full flex-row pt-2">
        <Button
          size="sm"
          className=" bg-transperent !hover:bg-transperent ml-auto hidden h-[26px]	rounded-full border border-primary px-[4px] text-xs font-bold text-primary	"
          variant="outline"
        >
          Recurring Journey
        </Button>
      </div>
    </div>
  )
}
