import {deleteFileService} from '@/services/nbasService'
import {FileDeleteRequest, FileDeleteResponse} from '@/types/upload'
import {AxiosError} from 'axios'

export async function deleteFileUseCase(params: FileDeleteRequest): Promise<FileDeleteResponse> {
  try {
    const response = await deleteFileService(params)
    return response
  } catch (err) {
    const error = {
      message:
        (err instanceof AxiosError && err.response?.data.errorMessage) ||
        (err as Error).message ||
        'An unknown error occurred',
    }
    throw error
  }
}
