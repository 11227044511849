import {DateRangeSelector} from '@/components/Drawer/ContentParams/DateRangeSelector'
import {ErrorArray} from '@/components/NBAConfigEditor/FormFieldError'
import {CountrySelector} from '@/components/NBAEditor/components/CountrySelector'
import {NBALanguage} from '@/components/NBALanguage'
import {Task} from '@/components/Task'
import {useFileUpload} from '@/hooks/useFileUpload'
import {useLanguages} from '@/hooks/useLanguages.hooks'
import {InputText} from '@/shared-components/InputText'
import {InputTextArea} from '@/shared-components/InputTextArea'
import {RadioButtonGroup} from '@/shared-components/RadioButtonGroup'
import {DatePicker} from '@/shared-components/shadcn/datepicker'
import {useAppSelector} from '@/store'
import {NBAFileUploadType} from '@/types/NBAFileUploadType'
import {NBAStatus} from '@/types/NBAStatus'
import {RewardTypeSchema, RewardTypeValues} from '@/types/rewardType'
import {LanguageType, TaskType} from '@/types/TaskSchema'
import {ThresholdTypeSchema, ThresholdTypeValues} from '@/types/threshholdType'
// import {getError} from '@/utils/errorUtils'
import {NBAConfigEditableFields} from '@ReduxActions'
import {DateTime} from 'luxon'
import {File} from 'pepsico-ds'
import {ChangeEvent, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'

interface Props {
  status: NBAStatus
  isNew?: boolean
  temporaryConfig: NBAConfigEditableFields
  selectedDateInJsDate: Date | undefined
  isPublishedOrCompleted: boolean
  onNameChanged: (e: ChangeEvent<HTMLInputElement>) => void
  onFixRewardPointsChanged: (e: ChangeEvent<HTMLInputElement>) => void
  onFixThresholdChanged: (e: ChangeEvent<HTMLInputElement>) => void
  onDescriptionChanged: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onPeriodDidChange: (start?: string, end?: string) => void
  onAddLanguage: (languages: Array<LanguageType>) => void
  onDisplayPeriodDidChange: (start?: string, end?: string) => void
  onAddTask: (task: Array<TaskType>) => void
  onDateDidChange: (date?: Date) => void
  onChangeAward: (award: number) => void
  onThresholdChanged: (threshold: number) => void
  onChangeRewardType: (value: number) => void
  onThresholdFileUpdate: (file: string) => void
  onRewardFileUpdate: (file: string) => void
  onCountryChanged: ({countryId, countryCode}: {countryId: number; countryCode: string}) => void
  onCountryCodeChanged: (val: string) => void
  formErrors: ErrorArray
}

export const NBASettings = ({
  status,
  isPublishedOrCompleted,
  //   isNew: isNewNBA,
  onNameChanged,
  onDescriptionChanged,
  onPeriodDidChange,
  onAddLanguage,
  onChangeAward,
  onChangeRewardType,
  onFixRewardPointsChanged,
  onThresholdChanged,
  onThresholdFileUpdate,
  onFixThresholdChanged,
  onRewardFileUpdate,
  // onRecurrenceDidChange,
  // onTimeExecutionsDidChange,
  // onRecurrenceDaysDidChange,
  temporaryConfig,
  onCountryChanged,
  selectedDateInJsDate,
  onDisplayPeriodDidChange,
  onAddTask,
  onDateDidChange,
  formErrors,
}: Props) => {
  const {formatMessage} = useIntl()

  const {country} = useAppSelector(state => state.common)
  useLanguages({countryId: country.id})

  useEffect(() => {
    onCountryChanged({countryId: country.id, countryCode: country.code})
    // onCountryCodeChanged(String(country.code))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country])

  if (isPublishedOrCompleted) {
    return null
  }

  const filesFixThreshold: Array<string> =
    (temporaryConfig.fixThreshold && [String(temporaryConfig.fixThreshold)]) || []
  const filesReward: Array<string> =
    (temporaryConfig.fixRewardPoints && [String(temporaryConfig.fixRewardPoints)]) || []

  const errorLanguage = formErrors?.filter(item => item.path[0] == 'languages') || []
  const errorTasks = formErrors?.filter(item => item.path[0] == 'tasks') || []
  const startDate = temporaryConfig.period?.start
    ? DateTime.fromISO(temporaryConfig.period?.start)
        .minus({days: 1})
        .toJSDate()
    : undefined

  const endDate = temporaryConfig.period?.end
    ? DateTime.fromISO(temporaryConfig.period?.end)
        .minus({days: 1})
        .toJSDate()
    : undefined

  return (
    // mb-16 is the DrawerBottomButtons height
    <div className="mb-16 flex flex-col ">
      <div className="mb-3 flex flex-col gap-2 px-1 text-text-secondary">
        <p className="text-sm font-normal">{formatMessage({id: 'NBA_BUILDER.EDITOR_SETTINGS_SUBHEADER'})}</p>
      </div>
      <div className="items-left flex h-fit w-full max-w-[100%] flex-col  overflow-auto px-1">
        <div className="mb-2 flex w-full max-w-full flex-row gap-1">
          <div className="flex w-[48.5%]  flex-col gap-4">
            <CountrySelector
              id="nba-country"
              label={formatMessage({id: 'ENTRY_CONDITION_CONTENT.COUNTRY'})}
              status={status}
              required
              value={country.code}
              disabled={true}
            />
          </div>
        </div>
        <NBALanguage
          error={errorLanguage}
          languageSelectors={temporaryConfig.languages}
          setLanguageSelectors={onAddLanguage}
        />

        <div className="mt-4 flex w-full max-w-full flex-row gap-1">
          <div className="flex  w-[48.5%]  flex-col gap-1">
            <InputText
              type="text"
              placeholder={formatMessage({id: 'ENTRY_CONDITION_CONTENT.NBA_NAME'})}
              label={formatMessage({id: 'ENTRY_CONDITION_CONTENT.NBA_NAME'})}
              maxLength={100}
              value={temporaryConfig.name}
              onChange={onNameChanged}
              required
              // error={getError(formErrors, 'name')}
            />
          </div>
        </div>
        <div className="mt-4 flex w-full max-w-full flex-row gap-1">
          <div className="flex  w-[48.5%]  flex-col gap-1">
            <InputTextArea
              placeholder={formatMessage({id: 'ENTRY_CONDITION_CONTENT.NBA_DESCRIPTION'})}
              label={formatMessage({id: 'ENTRY_CONDITION_CONTENT.NBA_DESCRIPTION'})}
              value={temporaryConfig.description}
              onChange={onDescriptionChanged}
              maxLength={150}
            />
          </div>
        </div>

        <div className="mt-4 flex w-full max-w-full flex-row gap-1">
          <DateRangeSelector
            start={temporaryConfig.period?.start}
            end={temporaryConfig.period?.start ? temporaryConfig.period?.end : undefined}
            onChange={onPeriodDidChange}
            // disabled={isPublishedOrCompleted}
            type={temporaryConfig.type}
            className="w-full"
            // error={getError(formErrors, 'period')}
            showInfo={false}
            isStartDateRequired
            isEndDateRequired
          />
        </div>
        <div className="mt-4 flex w-full max-w-full flex-row gap-1">
          <DateRangeSelector
            start={temporaryConfig.displayPeriod?.start}
            end={temporaryConfig.displayPeriod?.start ? temporaryConfig.displayPeriod?.end : undefined}
            onChange={onDisplayPeriodDidChange}
            // disabled={isPublishedOrCompleted}
            type={temporaryConfig.type}
            label={formatMessage({id: 'ENTRY_CONDITION_CONTENT.DISPLAY_DURATION'})}
            className="w-full"
            showInfo={false}
            // error={getError(formErrors, 'displayPeriod')}
            isStartDateRequired
            isEndDateRequired
          />
        </div>
        <div className="mt-4 flex w-full max-w-full flex-row gap-1">
          <Task error={errorTasks} taskSelectors={temporaryConfig.tasks} setTaskSelectors={onAddTask} />
        </div>
      </div>
      <hr className="my-6" />
      <div className="mb-3 flex flex-col gap-2 px-1 text-text-secondary">
        <p className="text-sm font-normal">Fill in the NBA points details</p>
      </div>
      <div className="items-left flex h-fit w-full max-w-[100%] flex-col  overflow-auto px-1">
        <div className="my-4 mt-2 flex w-full max-w-full flex-row gap-1">
          <RadioButtonGroup
            items={['On assigned task completion', 'On award pointing date']}
            label={'Award Assignment'}
            selectedItemIndex={temporaryConfig.onTaskCompletion == true ? 0 : 1}
            onUpdate={onChangeAward}
          />
        </div>
      </div>
      <div className="items-left flex h-fit w-full max-w-[100%] flex-col  overflow-auto px-1">
        {!temporaryConfig.onTaskCompletion ? (
          <div className="mb-2 flex w-full max-w-[48.5%] flex-row gap-1">
            <DatePicker
              id="param-selector-operator-value"
              className="max-w-xs"
              title={formatMessage({id: 'ENTRY_CONDITION_CONTENT.DATE'})}
              placeholder={formatMessage({id: 'ENTRY_CONDITION_CONTENT.SELECT_DATE'})}
              // error={errorMessage}
              date={selectedDateInJsDate}
              setDate={onDateDidChange}
              startingDate={startDate}
              endingDate={endDate}
              info="By default, it will be set up as the last date of the NBA"
              // disabledDays={{
              //   from: new Date(temporaryConfig.period?.start || new Date()),
              //   to: new Date(temporaryConfig.period?.end || new Date()),
              // }}
              required
              // error={getError(formErrors, 'onAwardDate')}
              // disabled={disabled || shouldBlockInput}
            />
          </div>
        ) : null}
      </div>
      <NBAReward
        temporaryConfig={temporaryConfig}
        filesReward={filesReward}
        onChangeRewardType={onChangeRewardType}
        onFixRewardPointsChanged={onFixRewardPointsChanged}
        onRewardFileUpdate={onRewardFileUpdate}
        formErrors={formErrors}
      />
      <NBAThreshold
        temporaryConfig={temporaryConfig}
        filesFixThreshold={filesFixThreshold}
        onThresholdChanged={onThresholdChanged}
        onThresholdFileUpdate={onThresholdFileUpdate}
        onFixThresholdChanged={onFixThresholdChanged}
        formErrors={formErrors}
      />
    </div>
  )
}

const NBAReward = ({
  temporaryConfig,
  filesReward,
  onChangeRewardType,
  onFixRewardPointsChanged,
  onRewardFileUpdate,
}: {
  temporaryConfig: NBAConfigEditableFields
  filesReward: Array<string>
  onChangeRewardType: (value: number) => void
  onFixRewardPointsChanged: (e: ChangeEvent<HTMLInputElement>) => void
  onRewardFileUpdate: (file: string) => void
  formErrors: ErrorArray
}) => {
  const {formatMessage} = useIntl()
  const [fileInputKey, setFileInputKey] = useState(Date.now())

  const {isUpdating, onUploadFile, progress, fileUploadError, onDeleteFile} = useFileUpload()

  // const error = formErrors?.filter(item => item.path[0] == 'fixRewardPoints') || []

  return (
    <>
      <div className="items-left flex h-fit w-full max-w-[100%] flex-col  overflow-auto px-1">
        <div className="my-2 flex w-full max-w-full flex-row gap-1">
          <RadioButtonGroup
            items={['Fix Reward Pts', 'Upload a Reward File']}
            label={'Reward Points'}
            selectedItemIndex={RewardTypeValues.indexOf(temporaryConfig.fixReward)}
            onUpdate={onChangeRewardType}
          />
        </div>
      </div>
      <div className="my-4 flex w-full max-w-full flex-row gap-1">
        {temporaryConfig.fixReward == RewardTypeSchema.Values.FIX_REWARD_POINTS ? (
          <div className="flex w-[50%]  flex-col gap-1">
            <InputText
              type="number"
              placeholder={formatMessage({id: 'ENTRY_CONDITION_CONTENT.FIX_NUMBER'})}
              label={formatMessage({id: 'ENTRY_CONDITION_CONTENT.FIX_NUMBER'})}
              maxLength={50}
              min="0"
              value={temporaryConfig.fixRewardPoints}
              onChange={onFixRewardPointsChanged}
              required
              onKeyPress={e => {
                if (e.code === 'Minus') {
                  e.preventDefault()
                }
              }}
              // error={error?.map(item => item.message).join(',') || undefined}
            />
          </div>
        ) : (
          <div className="mb-2 flex w-full max-w-full flex-row gap-1">
            <File
              key={fileInputKey} // Add a key to force re-render
              files={filesReward.map(file => file.split('/').pop() as string)}
              onUpdate={(e: File) => {
                const fileType = NBAFileUploadType.REWARDS
                onUploadFile(
                  e,
                  res => {
                    onRewardFileUpdate(res.filePath)
                    setFileInputKey(Date.now())
                  },
                  {fileType}
                )
              }}
              onDelete={() => {
                if (filesReward?.length > 0) {
                  onDeleteFile(filesReward[0]?.split('/').pop() as string, filesReward[0] as string)
                }

                onRewardFileUpdate('')
              }}
              className="w-[50%]"
              label="Upload File"
              accept=".csv"
              required
              disabled={isUpdating}
              error={fileUploadError}
              progressPerc={progress}
              variant={isUpdating ? 'uploading' : filesReward?.length > 0 ? 'complete' : 'edit'}
            />
          </div>
        )}
      </div>
    </>
  )
}

const NBAThreshold = ({
  temporaryConfig,
  filesFixThreshold,
  onThresholdChanged,
  onThresholdFileUpdate,
  onFixThresholdChanged, // formErrors,
}: {
  temporaryConfig: NBAConfigEditableFields
  filesFixThreshold: Array<string>
  onThresholdChanged: (threshold: number) => void
  onThresholdFileUpdate: (file: string) => void
  onFixThresholdChanged: (e: ChangeEvent<HTMLInputElement>) => void
  formErrors: ErrorArray
}) => {
  const {formatMessage} = useIntl()
  const [fileInputKey, setFileInputKey] = useState(Date.now())

  const {isUpdating, progress, fileUploadError, onUploadFile, onDeleteFile} = useFileUpload()
  // const error = formErrors?.filter(item => item.path[0] == 'fixThreshold') || []
  return (
    <>
      <div className="items-left flex h-fit w-full max-w-[100%] flex-col  overflow-auto px-1">
        <div className="mb-2 flex w-full max-w-full flex-row gap-1">
          <RadioButtonGroup
            items={['Fix Threshold', 'Upload a Threshold File']}
            label={'Task Threshold'}
            selectedItemIndex={ThresholdTypeValues.indexOf(temporaryConfig.treshhold)}
            onUpdate={onThresholdChanged}
            className="my-2 max-w-[355px]"
          />
        </div>
      </div>
      <div className="items-left flex h-fit w-full max-w-[100%] flex-col  overflow-auto px-1">
        {temporaryConfig.treshhold == ThresholdTypeSchema.Values.FIX_THRESHHOLD ? (
          <div className="flex w-[50%]  flex-col gap-1">
            <InputText
              type="number"
              placeholder={formatMessage({id: 'ENTRY_CONDITION_CONTENT.FIX_THRESHOLD'})}
              label={formatMessage({id: 'ENTRY_CONDITION_CONTENT.FIX_THRESHOLD'})}
              maxLength={50}
              min="0"
              value={temporaryConfig.fixThreshold}
              onChange={onFixThresholdChanged}
              onKeyPress={e => {
                if (e.code === 'Minus') {
                  e.preventDefault()
                }
              }}
              required
            />
          </div>
        ) : (
          <div className="mb-2 flex w-full max-w-full flex-row gap-1">
            <File
              key={fileInputKey} // Add a key to force re-render
              files={filesFixThreshold.map(file => file.split('/').pop() as string)}
              onUpdate={(e: File) => {
                const fileType = NBAFileUploadType.THRESHOLD
                onUploadFile(
                  e,
                  res => {
                    onThresholdFileUpdate(res.filePath)
                    setFileInputKey(Date.now())
                  },
                  {fileType}
                )
              }}
              onDelete={() => {
                if (filesFixThreshold?.length > 0) {
                  onDeleteFile(filesFixThreshold[0]?.split('/').pop() as string, filesFixThreshold[0] as string)
                }
                onThresholdFileUpdate('')
              }}
              className="w-[50%]"
              label="Upload File"
              accept=".csv"
              disabled={isUpdating}
              required
              progressPerc={progress}
              variant={isUpdating ? 'uploading' : filesFixThreshold?.length > 0 ? 'complete' : 'edit'}
              error={fileUploadError}
            />
          </div>
        )}
      </div>
    </>
  )
}
