import DrawerBottomButtons from '@/components/Drawer/ContentParams/DrawerBottomButtons'
import {JourneryAction} from '@/components/NBAConfigEditor/JourneryAction'
import {TaskPreview} from '@/components/NBAConfigEditor/TaskPreview'
import {NBAStatusBadge} from '@/components/NBAStatusBadge/NBAStatusBadge'
import {DateCell, PeriodCell} from '@/components/NBAsListTable/components/CustomCells'
import {useAppDispatch, useAppSelector} from '@/store'
import {setEditMode} from '@/store/slices/commonSlice'
import {NBAStatus} from '@/types/NBAStatus'
import {GetNBAResponse} from '@/types/NBAs'
import {RewardTypeSchema} from '@/types/rewardType'
import {ThresholdTypeSchema} from '@/types/threshholdType'
import {closeNBADrawer, openNBADrawer, setActiveNBA} from '@ReduxActions'
import {getNBAUseCase} from '@UseCases'
import {useQuery} from '@tanstack/react-query'
import {format} from 'date-fns'
import {useIntl} from 'react-intl'

const LabelValueComponent = ({
  label,
  value,
  children,
  link,
}: {
  label: string
  value?: string | number | undefined
  children?: React.ReactNode
  link?: string | null
}) => (
  <div className="my-2 flex flex-col border-gray-100">
    <label className="mb-1 text-xs font-semibold">{label}</label>
    {link ? (
      <a href={link} className="text-primary" target="_blank" rel="noopener noreferrer">
        <p className="mb-1 break-all text-xs font-semibold">{value}</p>
      </a>
    ) : (
      value && <p className="break-normal	break-all	 text-xs">{value}</p>
    )}
    {children}
  </div>
)

export const NBADetails = () => {
  //nbaId,
  const {formatMessage} = useIntl()
  const {id} = useAppSelector(state => state.nbaConfig)
  const dispatch = useAppDispatch()

  const {isFetching, data, error} = useQuery({
    queryKey: ['nbas', id],
    queryFn: ({signal}) => getNBAUseCase({id}, signal),
    // staleTime: 60 * 1000, // 1 minutes
  })

  const onClose = () => dispatch(closeNBADrawer())

  const openEditNbaDrawer = (data: GetNBAResponse) => {
    data &&
      dispatch(
        setActiveNBA({
          ...data,
          status: data.status ?? undefined,
          period: {start: data.startDate, end: data.endDate},
          displayPeriod: {start: data.displayStartDate, end: data.displayEndDate},
          onAwardDate: data.onAwardDate ? format(data.onAwardDate, 'yyyy-MM-dd') : '',
          fixReward: isNaN(Number(data.fixRewardPoints))
            ? RewardTypeSchema.Values.REWARD_FILE
            : RewardTypeSchema.Values.FIX_REWARD_POINTS,
          treshhold: isNaN(Number(data.fixThreshold))
            ? ThresholdTypeSchema.Values.THRESHHOLD_FILE
            : ThresholdTypeSchema.Values.FIX_THRESHHOLD,
        })
      )
    dispatch(setEditMode(true))
    dispatch(
      openNBADrawer({
        selectedPage: 'nbaConfigEditor',
        title: formatMessage({id: 'NBA_BUILDER.EDIT_HEADER'}),
        canBeClosed: true,
      })
    )
  }

  const {countries} = useAppSelector(state => state.common)

  if (isFetching)
    return (
      <p className="text-md my-16 items-center text-center" data-testid="nbas-loading">
        {formatMessage({id: 'NOTIFICATION_DETAILS.LOADING_DETAILS_LABEL'})}
      </p>
    )
  if (error)
    return (
      <p className="text-md my-16 items-center text-center" data-testid="nbas-loading">
        {error.message || formatMessage({id: 'NOTIFICATION_DETAILS.ERROR_DETAILS_LABEL'})}
      </p>
    )
  if (data === undefined) return

  const {
    countryCode,
    name,
    description,
    status,
    languages = [],
    startDate,
    endDate,
    displayStartDate,
    displayEndDate,
    fixRewardPoints,
    fixThreshold,
    onAwardDate,
    tasks = [],
  } = data

  const country = countries.find(c => c.code === countryCode)

  const isFixRewardFile = typeof fixRewardPoints === 'string' && isNaN(Number(fixRewardPoints))
  const isFixThresholdFile = typeof fixThreshold === 'string' && isNaN(Number(fixThreshold))

  return (
    <div className="overflow-y-auto">
      <div className="mb-16 flex flex-col gap-6 ">
        <div className="flex flex-row px-2">
          <div className="flex h-full w-[30%] flex-col rounded-lg	 border border-primary-100  p-6 text-sm">
            <h4 className="mb-4 text-base font-bold	">{name}</h4>
            <div className="mb-1 flex flex-row	justify-between">
              <div className="flex flex-col">
                <label className="mb-1 text-xs	font-semibold	">
                  {formatMessage({id: 'ENTRY_CONDITION_CONTENT.COUNTRY'})}
                </label>
                <p className="text-xs	">{country?.name}</p>
              </div>
              <NBAStatusBadge status={status} />
            </div>
            <LabelValueComponent
              label={formatMessage({id: 'ENTRY_CONDITION_CONTENT.NBA_DISPLAY_NAME'})}
              value={languages[0].displayText}
            />

            <LabelValueComponent
              label={formatMessage({id: 'ENTRY_CONDITION_CONTENT.NBA_DESCRIPTION'})}
              value={description}
            />

            <LabelValueComponent label={formatMessage({id: 'ENTRY_CONDITION_CONTENT.DURATION'})}>
              <PeriodCell className="text-xs" value={{start: startDate, end: endDate}} />
            </LabelValueComponent>

            <LabelValueComponent label={formatMessage({id: 'ENTRY_CONDITION_CONTENT.DISPLAY_DURATION'})}>
              <PeriodCell className="text-xs" value={{start: displayStartDate, end: displayEndDate}} />
            </LabelValueComponent>

            <hr className="my-3" />
            <LabelValueComponent
              label={formatMessage({
                id: isFixRewardFile
                  ? 'ENTRY_CONDITION_CONTENT.REWARD_FILES'
                  : 'ENTRY_CONDITION_CONTENT.REWARD_FIX_POINTS',
              })}
              link={isFixRewardFile ? fixRewardPoints : null}
              value={isFixRewardFile ? fixRewardPoints.split('/').pop() : fixRewardPoints}
            />
            <LabelValueComponent
              label={formatMessage({
                id: isFixThresholdFile
                  ? 'ENTRY_CONDITION_CONTENT.TASK_FIX_THRESHOLDS_FILE'
                  : 'ENTRY_CONDITION_CONTENT.TASK_FIX_THRESHOLDS_NO',
              })}
              link={isFixThresholdFile ? fixThreshold : null}
              value={isFixThresholdFile ? fixThreshold.split('/').pop() : fixThreshold}
            />

            <LabelValueComponent label={formatMessage({id: 'ENTRY_CONDITION_CONTENT.AWARDING_DATE'})}>
              <DateCell className="text-xs" value={onAwardDate} />
            </LabelValueComponent>
          </div>
          <div className="flex h-full w-[70%]">
            <div className="w-full px-4">
              {status === NBAStatus.published && <JourneryAction />}
              <p className="border-b border-gray-100 pb-3 text-xs">{formatMessage({id: 'NBA_BUILDER.REVIEW_TASKS'})}</p>
              <div className="mt-4 grid grid-cols-2 gap-2">
                {tasks.map((task, idx) => (
                  <TaskPreview
                    key={idx}
                    idx={idx + 1}
                    task={task}
                    onClose={onClose}
                    status={status}
                    // showUploadResult={showUploadResult}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Draft - Delete (post MVP)/Edit - US-13416864*/}
        {status === NBAStatus.draft && (
          <DrawerBottomButtons
            testIdPrimary="continue-or-save-button"
            onClickPrimary={() => openEditNbaDrawer(data)}
            secondaryButtonLabel=""
            primaryButtonLabel={formatMessage({id: 'NBA_BUILDER.NBA_DETAILS_ACTION_EDIT'})}
            // isPrimaryDisabled={!isAllRequiredFilled || !hasUnsavedChanges}
          />
        )}

        {/* Published - Deactivate (post MVP)/Close - US-13416864 */}
        {status === NBAStatus.published && (
          <DrawerBottomButtons
            testIdPrimary="close-nba-button"
            // testIdSecondary="continue-or-save-button"
            onClickPrimary={onClose}
            // onClickSecondary={() => openEditNbaDrawer(data)}
            primaryButtonLabel={formatMessage({id: 'GENERAL.CLOSE'})}
            // secondaryButtonLabel={formatMessage({id: 'GENERAL.DEACTIVATE'})}
          />
        )}

        {status === NBAStatus.completed && (
          <DrawerBottomButtons
            testIdPrimary="deactivate-nba-button"
            onClickPrimary={onClose}
            primaryButtonLabel={formatMessage({id: 'GENERAL.CLOSE'})}
          />
        )}
      </div>
    </div>
  )
}
