// import {useNBAMetaConfig} from '@/hooks/useNBAMetaConfig'
// import {UnsavedChangesWarning} from '@/components/PageLeavingWarning/UnsavedChangesWarning'
import {BorderlessButton, PrimaryButton, SecondaryButton} from '@/shared-components/Button'
import React from 'react'
import {useIntl} from 'react-intl'

export type DrawerBottomButtonsProps = {
  primaryButtonLabel: string
  secondaryButtonLabel?: string
  testIdPrimary: string
  testIdSecondary?: string
  onClickSecondary?: () => void
  onClickPrimary: () => void
  isPrimaryDisabled?: boolean
  isSecondaryDisabled?: boolean
  isCancelButton?: boolean
  hasUnsavedChanges?: boolean
  onCancel?: () => void
}

const DrawerBottomButtons: React.FC<DrawerBottomButtonsProps> = ({
  testIdPrimary,
  testIdSecondary,
  secondaryButtonLabel,
  primaryButtonLabel,
  onClickPrimary,
  onClickSecondary,
  isPrimaryDisabled,
  isSecondaryDisabled,
  isCancelButton,
  onCancel,
  // hasUnsavedChanges,
}) => {
  const {formatMessage} = useIntl()
  // const {isPublishedOrCompleted} = useNBAMetaConfig()

  return (
    <div className="absolute bottom-0 left-0 flex h-16 w-full items-center justify-end gap-4 bg-white px-6 shadow-[0_35px_60px_-15px_rgba(47,56,155,0.8)]">
      {/* <UnsavedChangesWarning visible={hasUnsavedChanges} /> */}
      {isCancelButton && (
        <BorderlessButton
          data-testid={testIdSecondary}
          data-cy={testIdSecondary}
          variant="tertiary"
          onClick={() => onCancel && onCancel()}
        >
          {formatMessage({id: 'GENERAL.CANCEL_BUTTON'})}
        </BorderlessButton>
      )}
      {/* {isPublishedOrCompleted && (
        <SecondaryButton
          disabled={isSecondaryDisabled}
          data-testid={testIdSecondary}
          data-cy={testIdSecondary}
          onClick={onClickSecondary}
        >
          {formatMessage({id: 'GENERAL.CLOSE'})}
        </SecondaryButton>
      )} */}
      {/* {!isPublishedOrCompleted && (
        <> */}
      {secondaryButtonLabel && (
        <SecondaryButton disabled={isSecondaryDisabled} onClick={onClickSecondary}>
          {secondaryButtonLabel}
        </SecondaryButton>
      )}

      {primaryButtonLabel && (
        <PrimaryButton
          data-testid={testIdPrimary}
          data-cy={testIdPrimary}
          onClick={onClickPrimary}
          disabled={isPrimaryDisabled}
        >
          {primaryButtonLabel}
        </PrimaryButton>
      )}
      {/* </>
      )} */}
    </div>
  )
}

export default DrawerBottomButtons
