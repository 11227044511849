import {SUGGESIONS_QUERY_KEY} from '@/config/constants'
import {NBAFileUploadType} from '@/types/NBAFileUploadType'
import {getConfigTypesUseCase} from '@/useCases/getConfigTypesUseCase'
import {useQuery} from '@tanstack/react-query'

export const useSuggestions = (id: string | number | undefined) => {
  const {isFetching: isFetchingSuggestion, data: suggestionsData} = useQuery({
    queryKey: [SUGGESIONS_QUERY_KEY, id],
    queryFn: ({signal}: {signal: AbortSignal}) =>
      getConfigTypesUseCase({taskId: id, fileType: NBAFileUploadType.INPUT}, signal),
    retry: 1,
    enabled: !!Number(id),
    staleTime: 60 * 60 * 1000, // 1 hour
  })

  return {
    suggestionsData,
    isFetchingSuggestion,
  }
}
