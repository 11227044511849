import {NBALanguageSelector} from '@/components/Task/NBALanguageSelector'
import {useTasks} from '@/components/Task/useTasks.hooks'
import {useTasktypes} from '@/components/Task/useTasktypes.hooks'
import {DropdownSelector} from '@/shared-components/DropdownSelector'
import {RemoveParameterButton} from '@/shared-components/RemoveParameterButton'
import {DropdownItemType} from '@/types/DropdownItemType'
import {useEffect, useState} from 'react'
import {LanguageType, TaskType, TaskTypeNameEnum} from '@/types/TaskSchema'
import {useIntl} from 'react-intl'
import {ErrorArray} from '@/components/NBAConfigEditor/FormFieldError'
import {useFileUpload} from '@/hooks/useFileUpload'
import {File} from 'pepsico-ds'
import {NBAFileUploadType} from '@/types/NBAFileUploadType'
interface Props {
  position: number
  // isPublished: boolean
  currentTaskSelector: TaskType
  error?: ErrorArray
  updateSelectedValues: (id: number, updatedParamSelector: TaskType | null) => void
}

let globalTaskId: string

export const TaskSelect: React.FC<Props> = ({
  position,
  // isPublished,
  currentTaskSelector,
  updateSelectedValues,
  error,
}) => {
  const [fileInputKey, setFileInputKey] = useState(Date.now())

  const {formatMessage} = useIntl()

  /** only file change bugs */
  const [file, setFile] = useState(currentTaskSelector.filePath)
  const {isUpdating, onUploadFile, progress, fileUploadError, setFileUploadError, onDeleteFile} = useFileUpload()

  globalTaskId = currentTaskSelector.taskId as string

  useEffect(() => {
    setFileInputKey(Date.now())
  }, [currentTaskSelector.taskId])

  useEffect(() => {
    const updatedSelector: TaskType = {
      ...currentTaskSelector,
      filePath: file,
    }

    updateSelectedValues(position, updatedSelector)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  const {taskTypesData} = useTasktypes()
  const {tasksData, isFetchingTasks} = useTasks({typeId: String(currentTaskSelector.taskTypeId)})

  const removeCurrentSelector = () => {
    updateSelectedValues(position, null)
  }
  const onChangeType = (e: Array<DropdownItemType>) => {
    const updatedSelector: TaskType = {
      ...currentTaskSelector,
      taskTypeId: (e && e[0]?.id) || undefined,
      taskTypeName: ((e && e[0]?.displayText) as TaskTypeNameEnum) || undefined,
      taskId: undefined,
      taskName: undefined,
    }
    updateSelectedValues(position, updatedSelector)
    setFile('')
    setFileUploadError('')
  }

  const onChangeTaskName = (e: Array<DropdownItemType>) => {
    const updatedSelector: TaskType = {
      ...currentTaskSelector,
      taskId: (e && e[0]?.id) || undefined,
      taskName: (e && e[0]?.displayText) || undefined,
    }
    updateSelectedValues(position, updatedSelector)
    setFile('')
    setFileUploadError('')
  }

  const onAddLanguage = (languages: Array<LanguageType>) => {
    const updatedSelector: TaskType = {
      ...currentTaskSelector,
      languages,
    }

    updateSelectedValues(position, updatedSelector)
  }

  const onFileUpdate = (e: File) => {
    const fileType = NBAFileUploadType.INPUT
    onUploadFile(
      e,
      res => {
        setFile(res.filePath)
        setFileInputKey(Date.now())
      },
      {fileType, taskId: String(globalTaskId)}
    )
  }

  const taskTypes: Array<DropdownItemType> =
    taskTypesData?.content?.map(item => ({
      displayText: item.name,
      id: String(item.id),
      isBadge: false,
    })) || []

  const tasks: Array<DropdownItemType> =
    tasksData?.content
      ?.filter(item => item.taskTypeId == currentTaskSelector.taskTypeId)
      ?.map(item => ({
        displayText: item.name,
        id: String(item.id),
        isBadge: false,
      })) || []

  const filesValue: Array<string> =
    (currentTaskSelector.filePath && [currentTaskSelector?.filePath?.split('/').pop() as string]) || []
  const taskNameValue = tasks.filter(item => item.id == currentTaskSelector.taskId) || []

  const errorLanguage = error?.filter(item => item.path[2] == 'languages') || []

  return (
    <div className="mb-4 flex flex-col gap-4 text-sm font-bold leading-6">
      <div className="flex flex-row justify-between">
        {formatMessage({id: 'ENTRY_CONDITION_CONTENT.TASK'}, {position: position + 1})}
        {position > 0 && <RemoveParameterButton iconSize="small" onClick={removeCurrentSelector} />}
      </div>
      <div className="relative flex w-full flex-row  items-start justify-between gap-4">
        <div className="flex w-full max-w-[301px] flex-col gap-2">
          <DropdownSelector
            className="font-normal"
            label={formatMessage({id: 'NBA_BUILDER.NBA_TASK_TYPE'})}
            required
            items={taskTypes}
            onChange={onChangeType}
            value={taskTypes?.filter(item => item.id == currentTaskSelector.taskTypeId) || undefined}
          />
        </div>
        <div className="flex w-full max-w-[301px] flex-col gap-2">
          <DropdownSelector
            className="font-normal"
            label={formatMessage({id: 'NBA_BUILDER.NBA_TASK_NAME'})}
            required
            items={tasks}
            onChange={onChangeTaskName}
            loading={isFetchingTasks}
            value={
              taskNameValue.length > 0
                ? taskNameValue
                : [
                    {
                      displayText: '',
                      id: '',
                      isBadge: false,
                    },
                  ]
            }
            disabled={!currentTaskSelector.taskTypeId}
          />
        </div>
      </div>
      <NBALanguageSelector
        position={position}
        languageSelectors={currentTaskSelector.languages || []}
        setLanguageSelectors={onAddLanguage}
        taskId={currentTaskSelector.taskId}
        error={errorLanguage}
      />
      <div className="relative flex w-full flex-row  items-start justify-between gap-4">
        <div className="flex w-full max-w-[48.5%] flex-col gap-2">
          <File
            key={fileInputKey} // Add a key to force re-render
            files={filesValue}
            onUpdate={onFileUpdate}
            label={formatMessage({id: 'NBA_BUILDER.NBA_TASK_FILE'})}
            accept=".csv"
            required
            onDelete={() => {
              onDeleteFile(
                currentTaskSelector?.filePath?.split('/').pop() as string,
                currentTaskSelector?.filePath as string
              )
              setFile('')
            }}
            progressPerc={progress}
            disabled={isUpdating || !currentTaskSelector.taskId} // || !currentTaskSelector.taskTypeId
            variant={isUpdating ? 'uploading' : filesValue.length > 0 ? 'complete' : 'edit'}
            error={fileUploadError}
          />
        </div>
      </div>
    </div>
  )
}
