import {cn} from '@/helpers/shadcn/utils'
import {FieldLabel} from '@/shared-components/FieldLabel'
import {InfoOrError} from '@/shared-components/InfoOrError.tsx'
import {Calendar} from '@/shared-components/shadcn/calendar'
import {format} from 'date-fns'
import _ from 'lodash'
import {Calendar as CalendarIcon} from 'lucide-react'
import {ComponentPropsWithRef, RefObject, useCallback, useEffect, useRef, useState} from 'react'
import {useOnClickOutside} from 'usehooks-ts'

type DatePickerProps = ComponentPropsWithRef<'div'> & {
  date: Date | undefined
  setDate: (newDate?: Date) => void
  startingDate?: Date
  endingDate?: Date
  fromDate?: Date
  disabled?: boolean
  title?: string
  placeholder?: string
  required?: boolean
  info?: string
  error?: string
}

export const DatePicker = ({
  id,
  date,
  setDate,
  startingDate,
  endingDate,
  title,
  placeholder,
  className,
  required,
  info,
  error,
  ...props
}: DatePickerProps) => {
  const [showCalendar, setShowCalendar] = useState(false)

  const buttonRef = useRef<HTMLButtonElement>(null)
  const calendarWrapperRef = useRef<HTMLDivElement>(null)
  const calendarRef = useRef<HTMLDivElement>(null)

  const calendarPosition = useCalendarPosition({buttonRef, calendarRef})

  useOnClickOutside(calendarWrapperRef, () => setShowCalendar(false))

  const handleShowCalendar = () => {
    window.dispatchEvent(new Event('resize'))
    setShowCalendar(!showCalendar)
  }

  const onSetDate = (newDate: Date | undefined) => {
    setDate(newDate)
    setShowCalendar(false)
  }

  const onMonthChange = () => {
    // Trigger resize to recalculate calendar position
    setTimeout(() => window.dispatchEvent(new Event('resize')), 0)
  }

  return (
    <div
      ref={calendarWrapperRef}
      className={cn('group flex w-full flex-col items-start gap-2 text-sm', className)}
      aria-disabled={props.disabled}
    >
      <FieldLabel id={`${id}-show-calendar-button`} label={title} required={required} disabled={props.disabled} />

      <button
        id={`${id}-show-calendar-button`}
        data-testid="show-calendar-button"
        data-cy={`${id}-show-calendar-button`}
        ref={buttonRef}
        onClick={handleShowCalendar}
        className="picker-btn enabled:active:bg-gray-20 enabled: w-full rounded border bg-layer-01 px-2 py-1.5 text-text-primary outline-1 transition-all enabled:cursor-pointer group-aria-disabled:bg-button-disabled-background group-aria-disabled:text-button-disabled-text"
        disabled={props.disabled}
      >
        <div className="flex select-none items-center justify-between gap-3 text-text-secondary group-aria-disabled:text-text-disabled">
          <p>{date ? format(date, 'PPP') : placeholder ?? title}</p>
          <CalendarIcon className="h-4 w-4" />
        </div>
      </button>

      <InfoOrError info={info} error={error} id={id} />

      <div
        ref={calendarRef}
        className="shadow fixed z-10 rounded bg-white transition-opacity"
        style={{
          ...calendarPosition,
          opacity: showCalendar ? 1 : 0,
          visibility: showCalendar ? 'visible' : 'hidden',
        }}
      >
        <CalendarArrowUp buttonRef={buttonRef.current} calendarRef={calendarRef.current} />
        <Calendar
          {...props}
          data-testid={`${id}-picker`}
          data-cy={`${id}-picker`}
          fromDate={startingDate}
          toDate={endingDate}
          mode="single"
          selected={date}
          onSelect={onSetDate}
          disabled={{from: startingDate, after: endingDate}}
          onMonthChange={onMonthChange}
          initialFocus
        />
      </div>
    </div>
  )
}

type CalendarArrowUpProps = {
  buttonRef?: HTMLButtonElement | null
  calendarRef?: HTMLDivElement | null
}

const CalendarArrowUp = ({buttonRef, calendarRef}: CalendarArrowUpProps) => {
  if (!buttonRef || !calendarRef) return null

  const buttonRect = buttonRef.getBoundingClientRect()
  const calendarRect = calendarRef.getBoundingClientRect()

  const size = 12
  const isCalendarOverButton = calendarRect.top < buttonRect.top + buttonRect.height + size

  return (
    <div
      style={{
        width: 0,
        height: 0,
        borderLeft: `${size}px solid transparent`,
        borderRight: `${size}px solid transparent`,
        borderBottom: `${size}px solid white`,
        top: -size,
        left: `calc(50% - ${size}px)`,
        opacity: !isCalendarOverButton ? 1 : 0,
        zIndex: 100,
        visibility: !isCalendarOverButton ? 'visible' : 'hidden',
      }}
      className="absolute transition-opacity"
    />
  )
}

type UseCalendarPositionProps = {
  buttonRef: RefObject<HTMLButtonElement>
  calendarRef: RefObject<HTMLDivElement>
}

const useCalendarPosition = ({buttonRef, calendarRef}: UseCalendarPositionProps) => {
  const [calendarPosition, setCalendarPosition] = useState({left: 0, top: 0})

  const onWindowDidResize = useCallback(() => {
    if (!buttonRef?.current || !calendarRef.current) return

    const buttonRect = buttonRef.current.getBoundingClientRect()
    const calendarSize = calendarRef.current.getBoundingClientRect()
    const windowHeight = window.innerHeight

    const margin = 16
    const alignToBottomOfButton = buttonRect.top + buttonRect.height + margin
    const alignToBottomOfPage = windowHeight - margin - (calendarSize?.height ?? 0)
    const isCalendarOutOfWindowHeight = alignToBottomOfButton + calendarSize.height > windowHeight - margin

    const top = isCalendarOutOfWindowHeight ? alignToBottomOfPage : alignToBottomOfButton
    const left = buttonRect.left - (calendarSize.width - buttonRect.width) / 2

    setCalendarPosition({top, left})
  }, [buttonRef, calendarRef])

  useEffect(() => {
    const handleResize = _.throttle(onWindowDidResize, 100)
    handleResize()

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [onWindowDidResize])

  return calendarPosition
}
